import { Category, ComponentNavCategoryNavItem, ComponentNavImageNavItem, ComponentNavSimpleNavSubItem, GlobalExpandedNavigationBarDynamicZone } from '@/lib/remoteGraphQL';
import { Popover, Transition } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState, Fragment, useMemo } from 'react';
import { ChevronDownIcon, MenuIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { useNavStyles } from './style';
import { ImageFallback } from '../ImageFallback';
import { getStrapiMedia, toNextImageSrc } from '@/lib/media';
import { useGlobal } from '@/lib/hooks/GlobalHook';
import {FocusFooter} from "../FocusLayout/Footer"
import { useDarkMode } from '@/lib/hooks/useDarkModeHook';
interface DesktopDetailedCatProps {
  // desktopCatContainerRef: React.RefObject<HTMLDivElement>
  items: GlobalExpandedNavigationBarDynamicZone[];
  // displayShowMore: boolean;
}
export const ImageNavItem = ({ item, className }: { item: ComponentNavImageNavItem, className?: string }) => {
  const url = useMemo(() => item.url ?? (item.category && `/${item.category.slug}`) ?? (item.article_tag && `/tag/${item.article_tag.slug}`), [item]);
  // const title =useMemo(() => item.title??(item.category&& item.category.name) ?? (item.article_tag && item.article_tag.name) ?? "N/A", [item]);
  return (
    <div className={clsx("text-ellipsis whitespace-nowrap relative flex flex-col justify-center text-sm min-h-[200px]", className)} >
      {url ?
        <Link
          href={url}
          title={item.image?.alternativeText ?? item.image?.caption ?? ""}>

          <ImageFallback src={item.image?.url ?? ""} layout="fill" objectFit='contain' alt={item.image?.alternativeText ?? item.image?.caption ?? ""} />

        </Link> : <span>
          <ImageFallback src={item.image?.url ?? ""} layout="fill" objectFit='contain' alt={item.image?.alternativeText ?? item.image?.caption ?? ""} />
        </span>
      }
      {item.highlight && <span className="flex h-3 w-3 absolute -right-2 top-2">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500 "></span>
      </span>}
    </div>
  );
}

const ChildrenNavItem = ({ item, className }: { item: ComponentNavSimpleNavSubItem, className?: string }) => {
  const url = useMemo(() => item.url ?? (item.category && `/${item.category.slug}`) ?? (item.article_tag && `/tag/${item.article_tag.slug}`), [item]);
  const title = useMemo(() => item.title ?? (item.category && item.category.name) ?? (item.article_tag && item.article_tag.name) ?? "N/A", [item]);
  const {isDarkMode}=useDarkMode()
  return (
    <div className={clsx("text-ellipsis whitespace-nowrap relative flex flex-col justify-center text-sm", className)} >
      {url ?
        <Link href={url} className={clsx(item.highlight?"text-gray-700 hover:text-gray-800 dark:text-gray-200 hover:dark:text-gray-100": "text-gray-500 dark:text-dark-content-200 hover:dark:text-dark-content-100  hover:text-gray-800",
          isDarkMode&&"text-dark-content-200 hover:text-dark-content-100")}>{title}
        </Link> : <span className={clsx(item.highlight&&"text-gray-700 dark:text-gray-200")}>
          {title}
        </span>
      }
    </div>
  );
}

export const NavItem = ({ item/*, desktopCatContainerRef, displayShowMore*/, className }: { item: ComponentNavCategoryNavItem/*,  desktopCatContainerRef: React.RefObject<HTMLDivElement>, displayShowMore: boolean*/, className?: string }) => {
  const {  cx } = useNavStyles();
  const router = useRouter();

  const url = useMemo(() => item.url ?? (item.category && `/${item.category.slug}`) ?? (item.article_tag && `/tag/${item.article_tag.slug}`), [item])
  const title = useMemo(() => item.title ?? (item.category && item.category.name) ?? (item.article_tag && item.article_tag.name) ?? "N/A", [item])
  // const underlined =useMemo(() => (item.category&& router.query?.category == item.category.slug), [item.category, router.query?.category])
  return (
    <div className={clsx(" text-ellipsis whitespace-nowrap relative flex flex-col justify-start h-full gap-4 w-auto", className)} >
      {url ?
        <Link href={url} className={clsx("generic-link-l w-auto  ", item.highlight?"text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-100":"dark:text-dark-content-100 dark:hover:text-white")}>{title}
        </Link> : <span className={clsx(item.highlight&&"text-primary-600")}>
          {title}
        </span>
      }
      {
        item.subItems?.map((it, idx) => <ChildrenNavItem item={it!} key={idx} />)
      }
    </div>
  );
};

const DesktopDetailedCat = (props: DesktopDetailedCatProps) => {
  const { items } = props;
  const {global} =useGlobal();


  return (
    <Popover className="">
      {({ open, close }) => (
        <>
          <Popover.Button className="outline-none text-gray-500 dark:text-white justify-center p-4 hidden lg:block" title="更多">
            <MenuIcon
              className="w-5 h-5" />
          </Popover.Button>

          <Popover.Overlay className="fixed inset-0 opacity-30 mt-12" onMouseEnter={() => { if (open) close() }} />
          <Transition
            className="absolute z-10 w-full left-0  top-12"
            enter="transition duration-250 ease-out"
            enterFrom="transform scale-100 opacity-95"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-150 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-100 opacity-0"
          >
            <Popover.Panel className="w-full z-10 bg-white dark:bg-dark-main  rounded-b-md border-gray-500 border-x border-b p-8 shadow-md ">
              <div className="flex flex-col container mx-auto">

                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-8 mb-8">
                  {
                    items.map((it: any, idx) => it.__component == "nav.category-nav-item" ?
                      <NavItem item={it} className="text-black dark:text-dark-content-100" key={idx}/> :
                      it.__component == "nav.image-nav-item" ?
                        <ImageNavItem item={it} key={idx}/> :
                        <></>)
                  }

                </div>
                <FocusFooter />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default DesktopDetailedCat;