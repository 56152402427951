import React, { useContext } from 'react';

const LoginRegMsgContext = React.createContext({} as {
  isOpenDialog?:boolean,
  redirectUrl?:string,
  OpenDialogHandler:()=>void,
  RedirectUrlHandler:(url?:string)=>void,
});
export const LoginRegMsgProvider = (props: {children?: React.ReactNode})=>{
  const [isOpenDialog, setIsOpenDialog] = React.useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = React.useState<string>("");

  const OpenDialogHandler = () => {
    setIsOpenDialog(!isOpenDialog);
  }

  const RedirectUrlHandler = (url?:string) => {
    setRedirectUrl(url!);
  }

  return <LoginRegMsgContext.Provider value={{
    isOpenDialog: isOpenDialog,
    redirectUrl:redirectUrl,
    OpenDialogHandler: OpenDialogHandler,
    RedirectUrlHandler:RedirectUrlHandler,
  }} >
    {props.children}
  </LoginRegMsgContext.Provider>
}

export const useLoginRegMsg = () => {
  const loginRegMsg  = useContext(LoginRegMsgContext)
  return loginRegMsg 
}