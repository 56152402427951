import React, { useContext,useEffect } from 'react'
import moment from "moment";
import ReactGA from 'react-ga4'
import { useSession } from "next-auth/react";
import { UaEventOptions } from 'react-ga4/types/ga4';
import { useRouter } from 'next/router';

import {useDarkMode} from "./useDarkModeHook"



const ReactGAContext = React.createContext({} as {
  eventTracker:(config:any)=>void
});
export const ReactGAProvider = (props: {children?: React.ReactNode})=>{

  const {data, status} = useSession();

  const router=useRouter()

  const {isDarkMode}=useDarkMode()




 
  useEffect(()=>{
    if(status!="loading"){
      if(ReactGA.isInitialized){
        ReactGA.reset();
      }
    }
  },[data, status])

  
  useEffect(()=>{
    if(!ReactGA.isInitialized&& status!="loading"){
      initializeGA()
      //seo noPageViewGaEvent
      changePageGA()
    }
  },[ReactGA.isInitialized,router.pathname,status])




  const eventTracker =(config:any)=>{
    ReactGA.event({...config})
  }

  const changePageGA=()=>{
    let isArticlePage=false
    if(router.pathname==="/[category]/[slug]"){
      isArticlePage=true
    }
    ReactGA.set({
      ...(!isArticlePage&&{article_id:undefined}),
      ...(data?.user?.id ? {user_id: data?.user?.id}:{}),
      ...(data?.user?.isPaidMember?{planTier:data?.user?.planTier}:{})   });
    !isArticlePage&& ReactGA.send({ hitType: "pageview",theme:isDarkMode?"dark":"light"});
  }


  const initializeGA=()=>{    
    ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ??"", {gtagOptions:{
      ...(data?.user?.id ? {user_id: data?.user?.id}:{}
      )
    }});
  }


  return <ReactGAContext.Provider value={{
    eventTracker:eventTracker
  }} >
    {props.children}
  </ReactGAContext.Provider>
}

export const useReactGA = () =>  useContext(ReactGAContext)