import { useLiveQuery } from 'dexie-react-hooks';
import { FirebaseApp, getApps, initializeApp } from 'firebase/app';
import { getMessaging, isSupported, Messaging } from 'firebase/messaging';
import { createContext, useContext, useEffect, useState } from 'react';

import { notificationsDb as db } from '../dexie/notificationsDb';
import { firebaseConfig } from '../firebase/constant';
import { checkIsTokenValid, generateFcmToken, tokenInlocalforage } from '../firebase/utils';
import useDexie from './DexieHook';
import useWorkbox from './WorkboxHook';


const FirebaseContext = createContext<{
  firebaseMessaging?: Messaging;
  fcmToken?: string;
  isFcmReady: boolean;
  isSupportFcm: boolean;
  notificationPermission?: NotificationPermission;
}>({ isFcmReady: false, isSupportFcm: false });


export const FirebaseProvider = (props: { children?: React.ReactNode }) => {
  const { isSupportIndexDB } = useDexie();
  const { wbReg } = useWorkbox();
  const [firebaseMessaging, setFirebaseMessaging] = useState<Messaging>();
  const [fcmToken, setFcmToken] = useState<string>();
  const [isFcmReady, setIsFcmReady] = useState(false);
  const [isSupportFcm, setIsSupportFcm] = useState(false);

  // <----------------------- Get - Status - Permissions ----------------------->
  const notificationPermission = useLiveQuery<NotificationPermission | undefined>(
    async() => {
      if (!isSupportIndexDB) return undefined;
      const p = await db.status.get({key:"notificationPermission"}).catch(console.error)
      return p?.value as NotificationPermission
    }, [isSupportIndexDB]);


  // <----------------------- INIT - FCM_Token ----------------------->
  useEffect(() => {
    if (!wbReg || !isSupportFcm) return;

    const app: FirebaseApp = getApps()[0] ? getApps()[0] : initializeApp(firebaseConfig);
    const messaging = getMessaging(app)
    setFirebaseMessaging(messaging);

    if (notificationPermission !== "granted") return;
    tokenInlocalforage().then((existToken) => {
      if (existToken) {
        checkIsTokenValid(existToken)
          .then((res) => res.text())
          .then((text) => {
            if (text === "INVALID_TOKEN") {
              generateFcmToken(messaging, wbReg).then(token=>{
                setFcmToken(token);
                db.status.put({ key: "isOpenNotification", value: false }).catch(console.error)
              });
            } else {
              setFcmToken(existToken);
            }
          });
      } else {
        generateFcmToken(messaging, wbReg).then(setFcmToken);
      }
    });
  }, [isSupportFcm, notificationPermission, wbReg]);

  // <----------------------- INIT - Ready_State ----------------------->
  useEffect(() => {
    if (!wbReg || !firebaseMessaging) return;
    setIsFcmReady(true);
  }, [firebaseMessaging, wbReg]);

  // <----------------------- INIT - Is_Browser_Support_FCM ----------------------->
  useEffect(() => {
    if (!isSupportIndexDB) return;
    isSupported().then(setIsSupportFcm)
  }, [isSupportIndexDB]);
  


  return (
    <FirebaseContext.Provider
      value={{
        firebaseMessaging,
        fcmToken,
        isFcmReady,
        isSupportFcm,
        notificationPermission
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);

export default useFirebase;