import { getStrapiMedia } from "@/lib/media";
import { ComponentSectionsStaticHyperLink } from "@/lib/remoteGraphQL";
import CombinedLink from "../CombinedLink";
import NextImage from "next/legacy/image";
import { useGlobal } from "@/lib/hooks/GlobalHook";

export const StaticHyperLink= (props: { model: ComponentSectionsStaticHyperLink | null })=>{
  const {global} = useGlobal();
  const { model } = props;
  
  return (
    <CombinedLink  href={model?.url??"/"} target="_blank" rel="noreferrer" className="fixed" style={{
      ...model?.position as any
    }}
    >
      <div className=" w-[70px] h-[70px] md:w-[90px] md:h-[90px]  xl:w-[110px] xl:h-[110px] relative" >
        <NextImage src={getStrapiMedia(model?.image??global?.favicon)??""} layout="fill" objectFit="contain" alt={model?.alt} priority />
      </div>
    </CombinedLink>
  )}