import { getToken, Messaging } from 'firebase/messaging';
import localforage from 'localforage';
import { NotificationSubscribeRequestBody } from 'pages/api/notification/subscribe';

import { vapidKey } from './constant';
import { notificationsDb as db } from '../dexie/notificationsDb';

export type FcmTopic = "article" | "user" | "manual" | "developer-001";

// export type FcmTopic = "generic" | "member"
export type FcmSubscriptionMethod = "sub" | "unsub";


export interface WebNotification {
  messageId: string;
  data: {
    body?: string;
    image?: string;
    title?: string;
    url?: string;
  };

  // extra add
  isRead: boolean; 
}

// <----------------------- GET ----------------------->
export const tokenInlocalforage = async () => {
  return localforage.getItem<string>("fcm_token");
};

// <----------------------- CHECK ----------------------->

// <----------------------- METHOD ----------------------->
export const notificationMarkAsRead = async (messageId: string) => {
  await db.notifications.where("messageId").equals(messageId).modify({ "isRead" : true }).catch(console.error)
};

export const generateFcmToken = async (messaging: Messaging, wbReg: ServiceWorkerRegistration) : Promise<string|undefined> => {
  try {
    if (Notification.permission !== "granted") return;
    
    const newToken = await getToken(messaging, {
      vapidKey,
      serviceWorkerRegistration: wbReg,
    });
    localforage.setItem<string>("fcm_token", newToken);
    return newToken;
  } catch (e) {
    // console.log(e);
  }
};

export const checkIsTokenValid = (registrationToken: string): Promise<Response> => {
  const body: { registrationToken: string } = {
    registrationToken,
  };
  
  return fetch(`/api/notification/validate-token`, {
    method: "POST",
    body: JSON.stringify(body),
  });
};