import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { Workbox } from "workbox-window";
import { SwDataProps, SwMessageType } from "workers/constant";


const WorkboxContext = createContext<{
  wbReg?: ServiceWorkerRegistration
  msgWb?: (msg: SwMessageType, data: SwDataProps) => void
    }>({

    });


// provide method to post msg to wb
export const WorkboxProvider = (props: { children?: React.ReactNode }) => {
  const [wbReg, setWbReg] = useState<ServiceWorkerRegistration | undefined>();
  const [wb, setWb] = useState<Workbox>();
  useEffect(() => {
    if ('serviceWorker' in navigator){ 
      const wb = new Workbox("/sw.js");
      wb.register().then((reg)=>{
        setWbReg(reg)
        setWb(wb)
      });
    }
  }, []);

  const msgWb = useCallback((messageType: SwMessageType, data: SwDataProps) => {
    if (!wb) return;
    wb.messageSW({ type: messageType, data });
  },
  [wb]
  );

  return (
    <WorkboxContext.Provider value={{ wbReg, msgWb }}>
      {props.children}
    </WorkboxContext.Provider>
  );
};

const useWorkbox = () => useContext(WorkboxContext);

export default useWorkbox;