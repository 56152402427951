import { Article } from "@/lib/remoteGraphQL"
import { Box, Typography } from "@mui/material"
import moment from "moment"
import { FC ,useMemo} from "react"
import { isBrowser } from "react-device-detect"
import { useIsClient } from "usehooks-ts"
import CombinedLink from "../CombinedLink"
import ArticleImage from "./ArticleImage"
import PaidArticleLabel from "./widgets/PaidArticleLabel"
import clsx from "clsx";
import {ReactComponent as  PlanIcon} from "@/assets/play.svg"

moment.locale('zh-tw')
export const ArticleListItem:FC<{article:Article, preferredRatio?:"16:9"|"1:1", onHitClick?:()=>void}> =(props)=>{
  const isClient = useIsClient();
  const {article, preferredRatio} =props;

  const showPlayIcon=useMemo(()=>{
    return  article?.video?.youtubeUrl?true:false
  },[article])
  return  <CombinedLink
    href={`/${article?.category?.slug}/${article?.slug}`}
    scroll
    color="inherit"
    underline="none"
    onClick={props.onHitClick}
  >
    <div className={clsx(`flex flex-row-reverse rounded-md  items-center mobile:hover:bg-transparent transition-colors duration-500 `, (isBrowser&&isClient)?"hover:bg-gray-300 dark:hover:bg-dark-content-300 ":"")} title={article?.title}>
      
      <div className='grow  pl-4 pr-2 flex flex-col  relative  gap-1 py-1'>
        <PaidArticleLabel article={article}/>
        <div  className={"overflow-hidden text-ellipsis text-sm lg:text-lg  dark:text-white "} >
          <span className="line-clamp-2  break-all " >{article?.title}</span>
        </div>
        <span className=' flex items-center'>
          <span className="text-xs lg:text-base text-gray-500 dark:text-dark-content-200  grow ">{isClient ? moment(article?.publishAt ?? article?.published_at).fromNow() : ''}</span>
        </span>
      </div>
      <div className="h-[98px] relative  rounded-md overflow-hidden" style={{minWidth: `${preferredRatio==="16:9"?174:98}px` }}>
        <ArticleImage
          article={article!}
          objectFit="cover"
          width={preferredRatio==="16:9"?174:98}
          height={98}
          layout="fixed"
          wide={preferredRatio==="16:9"}
          // loader={({ src, width, quality }) => src}
        />
        {showPlayIcon&&<div className="w-8 h-8  bg-primary-500  absolute bottom-0 left-0 flex items-center justify-center rounded-tr-md "><PlanIcon  className="w-4 h-4 "/></div>}

      </div>
    </div>
  </CombinedLink>
}

export const ArticleListItemSkeleton=(props:{preferredRatio?:"16:9"|"1:1"})=>{
  const{preferredRatio}= props;
  return  <div className={clsx(`flex flex-row-reverse rounded-md  items-center mobile:hover:bg-transparent transition-colors duration-500 `)}>
      
    <div className='grow  pl-4 pr-2 flex flex-col  relative  gap-1 py-1'>
      <div  className={"overflow-hidden text-ellipsis text-sm lg:text-lg  dark:text-white "} >
        <span className="line-clamp-2  break-all w-32 bg-gray-200 animate-pulse h-6 rounded-md" ></span>
      </div>
      <span className=' flex items-center'>
        <span className="text-xs lg:text-base text-gray-200 dark:text-dark-content-200   h-4 w-12 rounded-md bg-gray-200 animate-pulse"></span>
      </span>
    </div>
    <div className="h-[98px] relative  rounded-md overflow-hidden" style={{minWidth: `${preferredRatio==="16:9"?174:98}px` }}>
      <div className="aspect-w-1 aspect-h-1 bg-gray-200 animate-pulse h-full"></div>

    </div>
  </div>
}