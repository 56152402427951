import { GlobalProvider, useGlobal } from "@/lib/hooks/GlobalHook";
import { LoginRegMsgProvider } from "@/lib/hooks/LoginRegMsgHook";
import { UserProvider } from "@/lib/hooks/UserHook";
import { Theme, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSession } from "next-auth/react";
import Head from "next/head";
import React, { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "tss-react/mui";
import { getStrapiMedia } from "@/lib/media";
import {
  ComponentSharedSeo,
  Global,
  MemberFunction,
} from "@/lib/remoteGraphQL";
import ReactGA from "react-ga4";
import clsx from "clsx";

//components
import { FocusFooter } from "./Footer";
import { ShowLoginRegMsgDialog } from "../ShowLoginRegMsgDialog";
import NextNprogress from "nextjs-progressbar";
import { OpenAppButton} from "../OpenAppButton"

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxWidth: "100vw",
    overflowX: "hidden",
    position: "relative",
  },

  content: {
    flex: "1 0 auto",
    // paddingTop: theme.spacing(8),
    // paddingBottom: theme.spacing(8),
  },

  header: {
    flexShrink: 0,
  },
  footer: {
    flexShrink: 0,
  },
}));

const FocusLayout = (props: FocusLayoutProps) => {
  const { children } = props;
  const { global, memberFunction } = useGlobal();
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const { data, status } = useSession();

  return (
    <UserProvider>
      <LoginRegMsgProvider>
        {/* <NoSsr>  */}
        <div className={clsx(classes.root, "relative min-h-screen dark:bg-dark-main")}>
          <Head>
            <link
              rel="apple-touch-icon"
              href={getStrapiMedia(global?.favicon)?.toString()}
            />
            <link
              rel="shortcut icon"
              href={getStrapiMedia(global?.favicon)?.toString()}
            />
          </Head>
          {/* <Nav /> */}
          <NextNprogress
            color={theme.palette.primary.main}
            startPosition={0.3}
            stopDelayMs={200}
            height={4}
            showOnShallow={true}
          />
        
          <div
            className={clsx(
              classes.content,
              "pb-16 pt-14 md:pt-16 md:container md:mx-auto lg:px-auto"
            )}
          >
            {/* <Container maxWidth="lg" className={clsx(classes.content,"pt-0 lg:pt-20")}> */}
            {children ?? <></>}
            <ToastContainer position="bottom-right" />
            <ShowLoginRegMsgDialog />
            {/* </Container> */}
          </div>
          <OpenAppButton />
          <div className="bg-gray-100 dark:bg-dark-base-200">
            <FocusFooter  />
            {/* <Footer global={global} className={cx(classes.footer, "fixed bottom-0 w-full hidden md:block")}/>
          <MobileBottomBar  global={global} /> */}
          </div>
        </div>

        {/* </NoSsr>      */}
      </LoginRegMsgProvider>
    </UserProvider>
  );
};

export type FocusLayoutProps = {
  children?: React.ReactNode;
  global?: Global;
  memberFunction?: MemberFunction;
  seo?: ComponentSharedSeo;
};
export default FocusLayout;
