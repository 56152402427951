import { Category, GlobalNavigationBarDynamicZone } from '@/lib/remoteGraphQL';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState, Fragment, useMemo } from 'react';

import { useNavStyles } from './style';

interface DesktopCatProps {
  // desktopCatContainerRef: React.RefObject<HTMLDivElement>
  items: GlobalNavigationBarDynamicZone[];
  // displayShowMore: boolean;
}

const NavItem = ({ item/*, desktopCatContainerRef, displayShowMore*/, className }: { item:GlobalNavigationBarDynamicZone/*,  desktopCatContainerRef: React.RefObject<HTMLDivElement>, displayShowMore: boolean*/, className?: string }) => {
  const { classes, cx } = useNavStyles();
  const router = useRouter();

  const [isOverflow, setIsOverflow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const item = ref.current;
  //   const parent = desktopCatContainerRef.current;
  //   if (!item || !parent) return;
  //   const handleResize = () => setIsOverflow(item.offsetLeft + item.clientWidth > parent.clientWidth);

  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [desktopCatContainerRef, displayShowMore]);

  const url =useMemo(() => item.url?? (item.category&& `/${item.category.slug}`) ?? (item.article_tag && `/tag/${item.article_tag.slug}`), [item])
  const title =useMemo(() => item.title??(item.category&& item.category.name) ?? (item.article_tag && item.article_tag.name) ?? "N/A", [item])
  const underlined =useMemo(() => (item.category&& router.query?.category == item.category.slug), [item.category, router.query?.category])

  return (
    <div className={cx( "text-ellipsis whitespace-nowrap relative flex flex-col justify-center h-full", isOverflow ? 'invisible' : '', className)} ref={ref}>
      {url ?
        <Link href={url} className={underlined ? classes.hold : classes.a}>
          {title}
        </Link> : <span>
          {title}
        </span>
      }
    </div>
  );
};

const DesktopCat = (props: DesktopCatProps) => {
  const {  items } = props;


  return (
    <div className="relative overflow-hidden h-full hidden lg:block flex-1">
      <div className="flex gap-8 h-full">
        {items?.map((it:GlobalNavigationBarDynamicZone, idx) => (
          <NavItem item={it} key={idx} className={it.highlight?"font-bold text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-100":"text-gray-500 dark:text-gray-300 font-light"} />
        ))}
      </div>
    </div>
  );
}

export default DesktopCat;