import React from "react";
import { Chip } from "@mui/material";
import { Article } from "@/lib/remoteGraphQL";
import { ReactComponent as CommentCrownIcon } from "@/assets/article/vip-crown-cmmt.svg";

const PaidArticleLabel= (props:{article?: Article | null})=>{
  const {article} = props;
  return article?.paidMemberOnly ?
    <span className="flex flex-row  items-center text-gary-500 dark:text-dark-content-100 text-xs"><CommentCrownIcon className="w-4 h-4 text-primary-500 mr-2"/>付費會員限定</span>
    :<></>
}
export default PaidArticleLabel;