import { TextField, Grid, Button, Box} from '@mui/material';
import React, { ChangeEvent, useEffect, useState} from 'react';
import { fetchAPI } from '@/lib/api';
import { Article } from "@/lib/remoteGraphQL"
import { ArticleListItem } from "../article/ArticleListItem";
import { useDebounce } from 'usehooks-ts';
import { ArrowDropDown, Height } from '@mui/icons-material';

interface pagingArticle {
  articles: Article[];
  totalCount: number;
  perPage: number;
  totalPage: number;
}

const Meilisearch = (props : {perPage: number, onHitClick:()=>void, defaultSearchText?:string}) => {
  const [searchText, setSearchText] = useState<string>(props.defaultSearchText??'');
  const [suggestArticles, setSuggestArticles] = useState<Article[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const debouncedValue = useDebounce<string>(searchText, 500);

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const fetchData = async () => {
    if(searchText.trim()){
      const res = await fetchAPI<pagingArticle>(`/articles/search?q=${searchText}&perPage=${props.perPage}&page=${1}`)
      setSuggestArticles(res.articles);
      setHasMore((!res.articles || !res.articles.length)? false: true);
      setTotalPage(res.totalPage);
      setPage(2);
    }
    else{
      setSuggestArticles([]);
      setHasMore(false);
    }
  }

  const fetchMoreData = async () => {
    if(page > totalPage){
      setHasMore(false);
      return;
    }

    const res = await fetchAPI<pagingArticle>(`/articles/search?q=${searchText}&perPage=${props.perPage}&page=${page}`)
    
    setSuggestArticles([...suggestArticles, ...res.articles]);
    if (!res.articles || !res.articles.length) {
      setHasMore(false);
    }
    setTotalPage(res.totalPage);

    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    fetchData();
  }, [debouncedValue])
  useEffect(() => {
    if(props.defaultSearchText)
      setSearchText(props.defaultSearchText);
  }, [props.defaultSearchText])

  return (
    <div>
      <TextField label="請輸入文字搜尋" 
        fullWidth
        value={searchText}
        onChange={handleSearchTextChange}
      />
      {searchText.trim() && (
        !suggestArticles.length? 
          <div className="w-full p-8 text-center"  >找不到文章資料</div>:
          <Grid container direction="column" sx={{ marginTop: "2.5rem" }}>
            {suggestArticles?.map( it =>
              <Grid item key={it.id} sx={{ marginBottom: "1.5px" }}>
                <ArticleListItem article={it} onHitClick={props.onHitClick}/>
              </Grid>
            )}

            {hasMore?
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button variant="outlined"
                  className="text-gray-500 border-gray-500" onClick={fetchMoreData} endIcon={<ArrowDropDown />}>
                查看更多結果
                </Button>
              </Box>:
              <div className="w-full p-8 text-center"  >已經是全部找到的文章了</div>}

          </Grid>
      )}
    </div>
  );
};

export default Meilisearch;