import React, { useState } from "react";
import clsx from "clsx";
import Link from "next/link";

import CombinedLink from "./CombinedLink";

import { ReactComponent as CloseIcon } from "@/assets/icon_close.svg";
import { Global } from "../lib/remoteGraphQL";
import Image from "next/legacy/image";
export const MobileBottomBar: React.ComponentType<{
  global: Global;
}> = (props: { global: Global }) => {
  const { global } = props;
  const [show, setShow] = useState(false);

  return (
    <div
      className={clsx(
        " fixed lg:hidden bottom-0 z-[999]  shadow-lg w-full   h-[4.5rem] bg-white dark:bg-dark-base-200 dark:text-dark-content-100 flex flex-row  items-center py-2 px-2 ",
        !show && "hidden"
      )}
    >
      <button
        className="m-3 "
        onClick={() => {
          setShow(false);
        }}
      >
        <CloseIcon  className="w-12 h-12"/>
      </button>
      <div className=" flex flex-row items-center  flex-1  ">
        {global?.favicon && (
          <div className=" bg-primary-500  rounded-[10px] flex flex-row items-center justify-center">
            <Image
              priority
              src={global.favicon?.url ?? ""}
              layout="fixed"
              width={56}
              height={56}
            />
          </div>
        )}
        <div className="flex-1 text-base mx-2">
          <div>下載 Channel C App</div>
          <div>掌握香港即時新聞資訊</div>
        </div>
      </div>
      <CombinedLink href={"/app-download"} target={"_blank"}>
        <button
          className={
            "rounded-2xl  flex flex-row items-center justify-center w-14 h-8  bg-primary-500 text-gray-500 dark:text-dark-content-300 "
          }
        >
          開啟
        </button>
      </CombinedLink>
    </div>
  );
};
