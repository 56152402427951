import type { FC, PropsWithChildren} from 'react';
import { memo} from 'react';
import { ReactNode, createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import type { NextPage } from 'next';
import DefaultLayout  from '@/components/Layout';
import FocusLayout from "@/components/FocusLayout"
import { PropsOf } from '../utils/typing-helper';
export type AvailableLayout = typeof DefaultLayout;
export type LayoutType = "default" | "focus" ;
interface LayoutTypeToLayout {
  "default": PropsOf<typeof DefaultLayout>,
  "focus": PropsOf<typeof FocusLayout>,
}

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
   layout?: LayoutType
}

export interface LayoutContextInterface {
}

export type UseLayout = LayoutContextInterface

const LayoutCtx = createContext<LayoutContextInterface | null>(null);

export const LayoutProvider: FC<PropsWithChildren<{
  layoutProps: LayoutTypeToLayout,
  layoutType?: LayoutType,
}>> = ({ layoutProps, layoutType, children }) => {
  // const LayoutType = useMemo(()=>props)
  const Layout = useCallback((props: PropsWithChildren<unknown>) => {
    switch (layoutType) {
    case "focus":
      return <FocusLayout {...layoutProps.default}>{props.children}</FocusLayout>
    default:
      return <DefaultLayout  {...layoutProps.default}>{props.children}</DefaultLayout>
    }
  }, [layoutProps, layoutType])

  return <LayoutCtx.Provider value={{}}>
    <Layout>{children}</Layout>
  </LayoutCtx.Provider>
}

/**
 * 
 * @returns currently serve no purpose
 */
export function useLayout(): UseLayout {
  const ctx = useContext(LayoutCtx);
  return ctx!;
}

export default useLayout;
