import '../assets/css/ckeditor5.css';
import '../styles/globals.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { FirebaseProvider } from '@/lib/hooks/FirebaseHook';
import { NotificationProvider } from '@/lib/hooks/NotificationHook';
import { WorkboxProvider } from '@/lib/hooks/WorkboxHook';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { SessionProvider } from 'next-auth/react';
import React, { createContext, useEffect, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ComponentSharedSeo, Global } from '../lib/remoteGraphQL';

import type { AppProps } from 'next/app'
import type { EmotionCache } from "@emotion/cache";
import { DexieProvider } from '@/lib/hooks/DexieHook';
import Layout from '@/components/Layout';
import { LayoutProvider, NextPageWithLayout } from '@/lib/hooks/LayoutHook';
import { GlobalProvider } from '@/lib/hooks/GlobalHook';
import {DarkModeProvider} from "@/lib/hooks/useDarkModeHook"
import {ReactGAProvider} from "@/lib/hooks/useReactGAHook"
import moment from 'moment';
import Head from 'next/head';
import { useIsClient } from 'usehooks-ts';
declare module '@mui/styles/defaultTheme' {
  type DefaultTheme = Theme
}
export const queryClient = new QueryClient({
  defaultOptions:{
    queries: {
      staleTime: process.env.NODE_ENV==="development" ? 1000 * 60 : 1000 * 60, // 10 second for DEV, 1 minute for production
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  }
})
export type GlobalContextType = {
  defaultSeo: ComponentSharedSeo,
  siteName?: string
}
export const GlobalContext = createContext<GlobalContextType>({
  defaultSeo: {
    id: "",
    metaTitle: "",
    metaDescription: "",
  },
  siteName: "Channel C"
})

export const createEmotionCache = () => createCache({ key: 'css', prepend: true });

const letterSpacing = "1px";
export const theme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontFamily:"Noto Sans TC",
    fontSize: 16,
    button:{
      fontWeight:500,
      justifyContent:"flex-start",
      letterSpacing:letterSpacing,
    },
    caption:{
      letterSpacing:letterSpacing,
    },
    body1:{
      fontWeight:400,
      letterSpacing:letterSpacing,
    },
    h1:{
      fontWeight:800,
      letterSpacing:letterSpacing,
    },
    h2:{
      fontWeight:600,
      letterSpacing:letterSpacing,
    },
    h3:{
      fontWeight:500,
      letterSpacing:letterSpacing,
    },
    h4:{
      fontSize: "1.3rem",
      fontWeight:500,
      letterSpacing:letterSpacing,
    },
    h5:{
      fontSize: "1.2rem",
      fontWeight:500,
      letterSpacing:letterSpacing,
    },
    h6:{
      fontSize: "1.1rem",
      fontWeight:400,
      letterSpacing:letterSpacing,
    }
  },
  palette: {
    primary: {
      // main: "#f5d960"
      main: "#f5df4d"
    },
    secondary: {
      // main: "#453b39"
      main: "#939597"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768, //+1
      lg: 1024, //+1
      xl: 1280, //+1
    },
  },
});

let muiCache: EmotionCache | undefined = undefined;
export const createMuiCache = () => muiCache = createCache({ "key": "mui", "prepend": true });


function MyApp({ Component, pageProps: { session, ...pageProps } }: MyAppProps) {
  const layoutProps= useMemo(()=>({
    "default": {
    },
    "focus":{}
  }),[])
  useEffect(()=>{
    moment.locale('zh-tw')
  },[])
  const isClient = useIsClient();
  return (<>
    <SessionProvider
      session={session}
      refetchInterval={5 * 60}
      refetchOnWindowFocus={true}
    >
      <QueryClientProvider client={queryClient}>
        <DexieProvider>
          <WorkboxProvider>
            <FirebaseProvider>
              <NotificationProvider>
                <CacheProvider value={muiCache ?? createMuiCache()}>
                  <DarkModeProvider>
                    <ThemeProvider theme={theme}>
                      <ReactGAProvider>
                        <CssBaseline />
                        <GlobalProvider value={{global:pageProps?.global as any,memberFunction:pageProps?.memberFunction as any}}>
                          <LayoutProvider
                            layoutType={(Component as NextPageWithLayout).layout??"default"}
                            layoutProps={layoutProps} key={"layout-"+(Component as NextPageWithLayout).layout??"default"}>
                            <Component {...pageProps} />
                          </LayoutProvider>
                        </GlobalProvider>
                      </ReactGAProvider>
                    </ThemeProvider>
                  </DarkModeProvider>
                </CacheProvider>
              </NotificationProvider>
            </FirebaseProvider>
          </WorkboxProvider>
        </DexieProvider>
      </QueryClientProvider>
    </SessionProvider>
    <Head>
      {isClient && !(/bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent))&&
              <link rel='stylesheet' href='https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;500;700&display=swap' />}
    </Head>
  </>
  );
}

export type MyAppProps = {
  global: Global;
  pageProps: any;
} & AppProps
export default MyApp
