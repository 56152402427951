import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { makeStyles } from 'tss-react/mui';
import clsx from "clsx"
const useStyles = makeStyles()(theme => ({
  bttbBtn:{
    height: "45px",
    width: "45px",
    justifyContent:"center",
    padding:"0px",
    margin:"0px",
    maxWidth:"45px",
    minWidth:"45px",
    backgroundColor: theme.palette.secondary.main,
  },
}));

const LIMIT_SCROLL_Y = 120;

const BackToTopButton = (props:{showingSubscriptionBar:boolean})=>{
  const {showingSubscriptionBar}=props
  const router =useRouter()
  const { classes, cx } = useStyles();
  const [isShowButton, setShowButton]=useState(false);


  const isArticlePage=useMemo(()=>{
    return router.pathname==="/[category]/[slug]"
  },[router.pathname])


  useEffect(() => {
    const handleScroll = (e: Event) => {
      setShowButton(window.scrollY > LIMIT_SCROLL_Y);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backToTop = useCallback(() => {
    smoothscroll.polyfill();
    scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return <>
    <div className={cx("transition-all duration-300", isShowButton?"opacity-100":"opacity-0")}>
      <div className={clsx("fixed right-6 z-50 ", isArticlePage ?"bottom-14 lg:bottom-6" :"bottom-6", showingSubscriptionBar&&"lg:bottom-20")} >
        <Button onClick={backToTop} variant="contained" color='secondary' className={cx(classes.bttbBtn)} aria-label="回到最頂" >
          <KeyboardArrowUpIcon />
        </Button>
      </div>
    </div>
  </>; 
}

export default BackToTopButton;