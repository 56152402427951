import { getStrapiURL } from '@/lib/api';
import { AvatarProps } from '@mui/material/Avatar';
import { useUser } from '@/lib/hooks/UserHook';
import { CommonProps } from '@mui/material/OverridableComponent';
import clsx from 'clsx';
import { ImageFallback } from './ImageFallback';
import { useSession } from 'next-auth/react';
import { useEffect, useMemo } from 'react';
import {useProfileImage} from "@/lib/hooks/useProfileImage"

export const CustomAvatar = (props: CommonProps & AvatarProps & {userId:string,isPaidMember?:boolean}) => {
  const {updateCurrentTime} = useUser()
  const {userId,isPaidMember, ...rest } = props;
  const {data} =useSession();
  const getIconUrl = "/users/getIcon";
  const {...avatarProps} = props as AvatarProps;
  const {...commonProps} = props as CommonProps;
  const isCurrentUser=useMemo(()=>data?.user?.id===userId, [data, userId]);

  const { profileImageSrc, profileFallbackSrc} = useProfileImage({userId ,isPaidMember} )


  // getStrapiURL(`${getIconUrl}?userId=${userId}&_t=${isCurrentUser?updateCurrentTime:0}&w=128`)
  return (
    <div className='w-full h-full relative'> {userId&&
      <ImageFallback className={clsx("rounded-full bg-gray-200 w-full h-full")} src={profileImageSrc}
        fallbackSrc={profileFallbackSrc}
        layout="responsive" width={40} height={40}
      />
    }     
      
    {/* <Avatar {...rest}  src={userId?getStrapiURL(`${getIconUrl}?userId=${userId}&_t=${updateCurrentTime}&w=128`):""} sizes="3rem"/> */}
    </div>
  )
}