import Image, { ImageProps } from "next/legacy/image";
import { useEffect, useState } from "react";
import NoPhoto from "../public/no_photo.png";

export const ImageFallback=(props:Omit<ImageProps, 'src'>&{  src?:string|StaticImageData|null,  fallbackSrc?:string|StaticImageData|null } ) =>{
  const{fallbackSrc,src, ...rest} = props;
  const [imgSrc, set_imgSrc] = useState(src??fallbackSrc ?? NoPhoto);

  useEffect(() => {
    set_imgSrc(src??fallbackSrc ?? NoPhoto);
  }, [src]);

  // useEffect(() => {
  //   set_imgSrc(imgSrc);
  // }, [imgSrc]);

  return (
    <Image
      {...rest}
      src={imgSrc}
      onLoadingComplete={(result) => {
        if (result.naturalWidth === 0) {
          // Broken image
          set_imgSrc(fallbackSrc ?? NoPhoto);
        }
      }}
      onError={() => {
        set_imgSrc(fallbackSrc ?? NoPhoto);
      }}
    />
  );
}