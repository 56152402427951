import Dexie, { Table } from 'dexie';
import { WebNotification } from '../firebase/utils';

export type NotificationStatus =
  | "isOpenNotification"
  | "hasNewNotifications"
  | "isNewNotificationsShown"
  | "isSubscribedArticle"
  | "isSubscribedManual"
  | "isSubscribedUser"

class NotificationClassedDexie extends Dexie {
  // 'notifications' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  notifications!: Table<WebNotification>;
  status!: Table<{ key: NotificationStatus; value: boolean } | { key: "notificationPermission"; value: NotificationPermission }>;

  constructor() {
    super("Notifications Database");
    this.version(1).stores({
      // Primary keys of tables
      notifications: "++id,messageId",
      status: "key",
    });
  }
}

export const notificationsDb = new NotificationClassedDexie();
