import React from "react";
import NextImage, { ImageProps } from "next/legacy/image";
import { getArticleImageUrl, getArticleThumbnailImageAltText, getArticleThumbnailImageUrl } from "../../lib/media";
import { Article } from "../../lib/remoteGraphQL";
const ArticleImage = (
  props: Partial<ImageProps> & {
    article: Article;
    wide?: boolean | null;
    height?: number | null;
    blur?: boolean;
  }
) => {
  const { article, wide, blur, ...rest } = props;
  return (
    <NextImage
      {...rest}
      src={getArticleImageUrl(article, wide ?? false, props.height)??''}
      alt={getArticleThumbnailImageAltText(article, wide ?? false)}
      placeholder={blur === false ?  "empty" : "blur"}
      blurDataURL={ blur === false
        ? undefined
        : `/_next/image?url=${getArticleThumbnailImageUrl(article, wide ?? false) ??
          (getArticleImageUrl(article, wide ?? false, 100) as string) ??
          ""}&w=128&q=1`}
    />
  );
};
export default ArticleImage;