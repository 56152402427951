import { getStrapiMedia } from '@/lib/media';
import { Global } from '@/lib/remoteGraphQL';
import NextImage from "next/legacy/image";
import CombinedLink from "../CombinedLink";
import clsx from "clsx"
import {ReactComponent as ChannelCLogo} from "@/assets/channelc-logo.svg"
import {ReactComponent as ChannelCLogoM} from "@/assets/cc-logo.svg"

const LogoLink = ({ global, mobile }: { global: Global, mobile?:boolean }) => {

  if(mobile){
    return <CombinedLink href="/" className="h-full flex items-center  ">
      <div className="  w-7 h-7 relative">        
        <ChannelCLogoM className='w-full h-full text-gray-500 dark:text-white'/>
      </div>
    </CombinedLink>
  }
  return (
    <CombinedLink href="/" className="h-full flex items-center max-mobileM:w-[140px] ">
      <ChannelCLogo  className='text-gray-500 dark:text-white h-9 '
      /> 
    </CombinedLink>
  );
};

export default LogoLink;