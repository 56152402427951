import { makeStyles } from 'tss-react/mui';
import { alpha, Theme } from '@mui/material/styles';

export const headerHeight = "3.25em";

export const useNavStyles = makeStyles({
  name: "MuiCustomStyle"
})((theme: Theme) =>
  ({
    grow: {
      flexGrow: 1,
    },
    title: {
      display: 'none',
      alignItems: 'right',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    buttomAlign: {
      alignItems: 'flex-end'
    },
    a: {
      // color: '#000',
      display: "block",
      color: "inherit",
      textTransform: 'uppercase',
      textDecoration: 'none',
      letterSpacing: '0.15em',

          
          
      [theme.breakpoints.down("lg")]: {
      },
      '&:hover': {
        '&:after': {
          width: '100%',
          left: '0'
        }

      },
      '&:after': {
        // background: 'none repeat scroll 0 0 transparent',
        bottom: '0px',
        content: '""',
        height: '5px',
        left: '50%',
        zIndex:'1',
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        transition: 'width 0.4s ease 0s, left 0.4s ease 0s',
        width: '0',
      },


    },
    hold: {
      color: "inherit",
      display: "block",
      textTransform: 'uppercase',
      textDecoration: 'none',
      letterSpacing: '0.15em',
            
      [theme.breakpoints.down("lg")]: {
        padding: '15px 12px',
      },

      '&:after': {
        // background: 'none repeat scroll 0 0 transparent',
        bottom: '0px',
        content: '""',
        height: '5px',
        left: '0',
        zIndex:'1',
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        // transition: 'width 0.4s ease 0s, left 0.4s ease 0s',
        width: '100%',
      },
    },
    menuLogin: {
      padding: '6px 6px',
      alignItems: 'center',
      justifyContent: 'center'
    },
 

    list: {
      width: 320,
      maxWidth:"100vw"
    },
    fullList: {
      width: 'auto',
    },
    DialogStyle: {
      minHeight: '95vh',
    },
    scrollableDialog: {
      '&::-webkit-scrollbar': {
        width: '15px',
      },
      '&::-webkit-scrollbar-track': {
        //   boxShadow:'inset 0 0 5px grey', 
        borderRadius: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.main,
        borderRadius: '5px',
        '&:hover': {
          background: 'yellow',
        }
      }
    },

    DividerColor: {
      backgroundColor: theme.palette.primary.main,
      // backgroundColor:"black",
    },
    svgIconColor: {

      fill: theme.palette.secondary.main,
    },
    // avatarSmall:{
    //     width: theme.spacing(4),
    //     height: theme.spacing(4),
    // },



  }));