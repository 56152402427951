import React,{ useEffect, useState } from "react"
import clsx from "clsx"
import CombinedLink from "./CombinedLink"
import { useSession } from "next-auth/react";
import {ReactComponent as CloseIcon} from "@/assets/icon_close.svg"
import BackToTopButton from "./BackToTopButton";

export const SubscriptionBar =()=>{

  const {data, status} = useSession();

  const [showing,setShowing]=useState(true)


  useEffect(()=>{
    if(data?.user?.isPaidMember){
      setShowing(false)
    }
  },[data])


  return (
    <>
      <div className={clsx(" fixed bottom-0 w-full  z-20  h-auto  hidden ",showing&&"lg:block")}>
        <div className="z-auto bg-white dark:bg-dark-base-200 shadow-md   h-[4.5rem]  px-14 flex flex-row  justify-between items-center">

          <div className=" flex flex-row items-center gap-6 ">
            <span className="dark:text-dark-content-100 font-bold text-2xl">立即成為自己友，同 ChannelC 一齊成長</span>
          
            <CombinedLink href={"/plan"}>
              <button className="h-10 bg-primary-500 text-gray-600 rounded-3xl text-lg px-8 dark:text-dark-content-300  ">立即訂閱</button>
            </CombinedLink>
          </div>

          <button className="" onClick={()=>{
            setShowing(false)
          }}><CloseIcon className=" w-4 h-4" />
          </button>
        </div>
      </div>


      <BackToTopButton showingSubscriptionBar={showing} />
    </>
  )
}