import React, { useState,useRef } from "react";
import clsx from "clsx";

type CustomRadioProps = {
  isOn: boolean;
  onClick: () => void;
  customClassName?: string;
};

export const CustomSwitchBtn = (props: CustomRadioProps) => {
  const { isOn, onClick, customClassName } = props;

  return (
    <div className=" relative">
      <div
        className={clsx(
          " relative   rounded-2xl flex transition-all  flex-row items-center  cursor-pointer ",
          isOn ? "bg-primary-500 justify-end  " : "bg-gray-500 ",
          customClassName
        )}
        onClick={onClick}
      >
        <div
          className={clsx(
            "   aspect-1 h-full   w-fit   bg-white rounded-full transition-all "
            , )}
        ></div>
      </div>
    </div>
  );
};
