import { getStrapiURL } from "./api"
import { Article, UploadFile } from "./remoteGraphQL"
import NoPhoto from "../public/no_photo.png";
const UrlForNextImage = [
  'https://staging-api.channelchk.com',
  'https://api.channelchk.com',
  'https://channelchk-dev.s3.ap-east-1.amazonaws.com',
  'https://channelchk.s3.ap-east-1.amazonaws.com',
  'https://channelchk-staging.s3.ap-east-1.amazonaws.com'
];

export const toNextImageSrc = (media: string | StaticImageData, query?: string) => {
  const src = (media as StaticImageData)?.src ? (media as StaticImageData)?.src : media as string;
  if (media.toString()?.startsWith(process.env.NEXT_PUBLIC_FRONTEND_URL ?? "https://channelchk.com"))
    return src;
  const matchedUrlPrefix = UrlForNextImage.find(it => src.startsWith(it))

  if (matchedUrlPrefix)
    return new URL(`/_next/image?url=${encodeURIComponent((media as StaticImageData)?.src ?? media)}${query?query:'&w=1920&q=75'}`, process.env.NEXT_PUBLIC_FRONTEND_URL).toString();
  else
    return src;
}
export const srcExtractor = (media: string | StaticImageData) => {
  return (media as StaticImageData)?.src ?? media;
}
export function getStrapiMedia(media?: UploadFile | null): string | StaticImageData {
  const imageUrl = media?.url?.startsWith("/")
    ? getStrapiURL(media?.url)
    : media?.url
  return imageUrl ?? NoPhoto
}

export function getCorrespondingImage(media?: UploadFile | null, height?: number | null, specificSize?: "large" | "medium" | "small" | "thumbnail" | null): UploadFile {
  return (specificSize != null && media?.formats != null && media?.formats[specificSize]) || (((height ?? 400) > 400 ?
    (media?.formats?.large ?? media?.formats?.medium) :
    media?.formats?.medium) ?? media)
}

export function getArticleImageUrl(article?: Article | null, wideFirst?: boolean | null, height?: number | null): string | StaticImageData {
  if (wideFirst)
    return getStrapiMedia(getCorrespondingImage(article?.wideImage, height) ?? getCorrespondingImage(article?.image, height));
  else
    return getStrapiMedia(getCorrespondingImage(article?.image, height) ?? getCorrespondingImage(article?.wideImage, height));
}

export function getArticleThumbnailImageUrl(article?: Article | null, wideFirst?: boolean | null): string | null {
  let result: string | StaticImageData = "";
  if (wideFirst)
    result = getStrapiMedia(article?.wideImage?.formats?.thumbnail ?? article?.image?.formats?.thumbnail) ?? getArticleImageUrl(article, wideFirst);
  else
    result = getStrapiMedia(article?.image?.formats?.thumbnail ?? article?.wideImage?.formats?.thumbnail) ?? getArticleImageUrl(article, wideFirst);
  if (typeof result == "string")
    return result;
  return null;
}
export function getArticleThumbnailImageAltText(article?: Article | null, wideFirst?: boolean | null): string {
  if (wideFirst)
    return article?.wideImage?.alternativeText ? article?.wideImage?.alternativeText:
      article?.image?.alternativeText ? article?.image?.alternativeText: article?.title ?? "";
  else
    return article?.image?.alternativeText ?article?.image?.alternativeText:
      article?.wideImage?.alternativeText ? article?.wideImage?.alternativeText: article?.title ?? "";
}