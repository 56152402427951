import React, {
  useMemo,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import {
  fetchAPI,
  authFetchAPI,
  fetchAPIWithBody,
  authFetchAPIWithBody,
  getStrapiURL,
} from "@/lib/api";
import { useSession } from "next-auth/react";
import { toast } from "react-toastify";
import { useUser } from "@/lib/hooks/UserHook";
import { getCorrespondingImage, getStrapiMedia } from "@/lib/media";

//type
import { UploadFile } from "@/lib/remoteGraphQL";
import { SessionUser } from "next-auth";
import { SelectPredefinedAvatarDialog } from "@/components/account/SelectPredefinedAvatar";
import { useGlobal } from "@/lib/hooks/GlobalHook";

//icon

export interface BasicInfoProps {
  // userInfo: SessionUser;
  children?: React.ReactNode;
  // memberFunction:MemberFunction
  userId?: string;
}

const SelectAvatarContext = createContext<{
  showSelectAvatarDialog?: boolean;
  setShowSelectAvatarDialog?: (boolean: boolean) => void;
    }>({});

export const SelectAvatarProvider = (props: BasicInfoProps) => {
  const { data } = useSession();
  const { memberFunction } = useGlobal();

  const { updateCurrentTimeHandler, updateCurrentTime } = useUser();
  const [showSelectAvatarDialog, setShowSelectAvatarDialog] = useState(false);

  const selectAvatarFunc = async (iconFile: UploadFile | null) => {
    let isSuccess = false;
    const form = new FormData();
    data?.user?.id && form.append("userId", data?.user?.id);
    if (iconFile) {
      form.append("selectedIcon", iconFile.id);
    } else {
      // form.append("selectedIcon", "");
      //todo remove profile image
    }

    const res = await fetch(`${getStrapiURL()}/users/selectIcon`, {
      method: "POST",
      body: form,
      headers: { Authorization: "Bearer " + data?.accessToken },
    });

    if (res.status === 200) {
      // popup the success dialog
      toast.success("更改成功");
      updateCurrentTimeHandler?.(); // this is the useContent Provider to refresh the Nav bar Icon part...
      isSuccess = true;
      setShowSelectAvatarDialog(false);
    } else {
      toast.error("更改失敗");
      isSuccess = false;
    }
    return isSuccess;
  };

  return (
    <SelectAvatarContext.Provider
      value={{ showSelectAvatarDialog, setShowSelectAvatarDialog }}
    >
      {props.children}
      <SelectPredefinedAvatarDialog
        open={showSelectAvatarDialog}
        onClose={() => setShowSelectAvatarDialog(false)}
        onAvatarSelected={(iconFile: UploadFile | null) => {
          iconFile && selectAvatarFunc(iconFile as UploadFile | null);
        }}
        availableAvatar={memberFunction?.predefinedAvatar ?? []}
      />
    </SelectAvatarContext.Provider>
  );
};

export const useSelectAvatarHook = () => {
  return useContext(SelectAvatarContext);
};

export default useSelectAvatarHook;
