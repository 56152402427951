import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  FC,
  RefObject,
} from "react";
import { useSession } from "next-auth/react";
import CombinedLink from "../CombinedLink";
import clsx from "clsx";
import { useRouter } from "next/router";
 
import {useDarkMode} from "@/lib/hooks/useDarkModeHook";


//components
import { CustomSwitchBtn } from "../CustomSwitchBtn";
import {CustomDropdown} from "../CustomDropdown"
//icon
import { ReactComponent as ArrowIcon } from "@/assets/menu-arrow.svg";
import { ReactComponent as CloseIcon } from "@/assets/icon_close_menu.svg";
import { ReactComponent as EmptyUserIcon } from "@/assets/user-account.svg";



export const DropdownMenu: FC<{ isOpen: boolean; close: () => void }> = (
  props
) => {
  const { isOpen, close } = props;
  const { data: session, status } = useSession();

  const {isDarkMode,changeTheme} = useDarkMode();

  const router = useRouter();

  const user = useMemo(() => session?.user, [session]);

  

  useEffect(() => {
    isOpen && close();
  }, [router.asPath]);

  const menuOptionsList = useMemo(() => {
    return [
      {
        options: [
          {
            title: "登入",
            href: "/auth/login",
            type: "login",
            showArrow: true,
          },
        ],
        enable: !user,
      },
      {
        options: [
          {
            title: "最新消息",
            href: "/auth/account/news",
            type: "",
            showArrow: true,
          },
        ],
        enable: user,
      },
      {
        options: [
          {
            title: "帳戶資料",
            href: "/auth/account/info",
            type: "",
            showArrow: true,
          },
          {
            title: "收藏庫",
            href: "/auth/account/favorites",
            type: "",
            showArrow: true,
          },
          {
            title: "自己友專屬福利",
            href: "/auth/account/benefits",
            type: "",
            showArrow: true,
          },
        ],
        enable: user,
      },
      {
        options: [
          {
            title: "商戶優惠",
            href: "/member/partner",
            type: "",
            showArrow: true,
          },
          {
            title: "會員活動",
            href: "/member/memberBenefits",
            type: "",
            showArrow: true,
          },
          { title: "訂閱計劃", href: "/plan", type: "", showArrow: true },
        ],
        enable: true,
      },
      {
        options: [
          {
            title: "通知",
            href: "/auth/account/notification-setting",
            type: "",
            showArrow: true,
          },
          // { title: "字型大小", href: "/", type: "", showArrow: true },
          { title: "黑夜模式", type: "darkMode", showArrow: false },
        ],
        enable: true,
      },
      {
        options: [
          {
            title: "登出",
            href: "/auth/logout",
            type: "logout",
            showArrow: false,
          },
        ],
        enable: user,
      },
    ];
  }, [user]);

  const filterOptionsList = useMemo(() => {
    return menuOptionsList.filter((it) => it.enable);
  }, [menuOptionsList]);

  return (
    <div className={clsx(" relative text-base ")}>
      <CustomDropdown isOpen={isOpen}  isFullScreen={true}>
        <div className=" h-full  ">
          <button onClick={close} className=" lg:hidden ">
            <CloseIcon className="w-12 h-12" />
          </button>
          <div className="w-full lg:w-[22.5rem] ">
            {filterOptionsList.map((section, index) => {
              return (
                <div
                  key={index}
                  className={clsx(
                    " border-b border-b-gray-100 lg:py-2 ",
                    index == filterOptionsList.length - 1 && "border-b-0"
                  )}
                >
                  <div>
                    {section.options.map((option, idx) => {
                      const isCurrentLink = option.href === router.asPath;
                      return (
                        <div
                          key={idx}
                          className={clsx(
                            "h-12 lg:h-14  whitespace-nowrap   hover:bg-gray-40 hover:dark:bg-dark-main "
                          )}
                        >
                          {option?.href && (
                            <CombinedLink
                              href={option.href}
                              aria-label={option.title}
                              className={clsx(
                                "pr-4  border-l-8  cursor-pointer h-full flex flex-row items-center justify-between ",
                                isCurrentLink
                                  ? "border-primary-500 bg-gray-40 dark:bg-dark-base-200 "
                                  : "border-transparent"
                              )}
                            >
                              <div
                                className={clsx(
                                  "pl-4 text-lg flex flex-row items-center  ",
                                  option.type == "logout" && "text-sm"
                                )}
                              >
                                {option.type === "login" && (
                                  <EmptyUserIcon className="mr-4 rounded-full overflow-hidden" />
                                )}
                                {option.title}
                              </div>
                              {option.showArrow && (
                                <div className=" ">
                                  <ArrowIcon />
                                </div>
                              )}
                            </CombinedLink>
                          )}
                          {option?.type == "darkMode" && (
                            <div
                              className={clsx(
                                "pr-4  border-l-8  border-transparent  h-full flex flex-row items-center justify-between "
                              )}
                            >
                              <div className="pl-4 text-lg">{option.title}</div>
                              <CustomSwitchBtn
                                isOn={isDarkMode}
                                onClick={changeTheme}
                                customClassName="w-9 h-6 p-[1px]"
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CustomDropdown>
    </div>
  );
};
