import React,{useMemo,useState,useEffect,useCallback} from "react"
import clsx from "clsx"
import {useRouter} from "next/router"
import { isAndroid, isBrowser, isMobile } from "react-device-detect";

import useTimer from "@/lib/hooks/TimerHook";





export const OpenAppButton =()=>{
  const router =useRouter()

  const [timer, timerActions] = useTimer();


  const isArticlePage=useMemo(()=>{
    return router.pathname==="/[category]/[slug]"
  },[router.pathname])

  const hiddenBtnPathnameList=useMemo(()=>{
    return [
      "/app",
      "/auth/account/favorites",
      "/auth/account/info",
      "/auth/account/benefits",
      "/auth/account/benefits/history",
      "/auth/account/benefits/detail/[id]",
      "/member/campaignRecords" ,    //活動紀錄
      "/member/savedCoupons",   //收藏優惠
      "/member/redeemList",   //領獎通知
    ] 
  },[])

  useEffect(()=>{
    window.addEventListener('pagehide', preventPopup);
    return   window.removeEventListener('pagehide', preventPopup);
  },[])

  const preventPopup=()=>{
    timerActions.reset()
    window.removeEventListener('pagehide', preventPopup);
  }
  

  const isShowButton=useMemo(()=>{
    return isMobile ? !hiddenBtnPathnameList.includes(router.pathname):false
  },[hiddenBtnPathnameList,router.pathname])


  useEffect(() => {
    if (timer == 0 ) router.push("/app")
  }, [timer]);
 
  
  const clickHandle = () => {
    const win: Window = window;

    win.location =`cchk:/${decodeURIComponent(router.asPath)}`;
    timerActions.start(3000);
  
  
  };

  return <>
    <div className={clsx("lg:hidden transition-all duration-300 z-10", isShowButton?"opacity-100":"opacity-0")}>
      <div className={clsx("fixed   left-1/2  -translate-x-1/2  ", isArticlePage?"bottom-14 lg:bottom-6" :"bottom-6")} >
        <button onClick={clickHandle}
          className={clsx("  z-50  bg-primary-500 text-gray-600  whitespace-nowrap rounded-xl h-6 px-4 text-xs  shadow-lg")} >
            用 App 開啟
        </button>
      </div>
    </div>
  </>; 


}
