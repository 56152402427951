import { Category, Global, GlobalNavigationBarDynamicZone } from '@/lib/remoteGraphQL';
import { Box, Button, Divider, Drawer, List, ListItem, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { SessionUser } from 'next-auth';
import React, { Fragment, useMemo } from 'react';

import { ReactComponent as FbSvg } from '../../assets/FB.svg';
import { ReactComponent as IgSvg } from '../../assets/IG.svg';
import { ReactComponent as SearchSvg } from '../../assets/Search.svg';
import { ReactComponent as YtSvg } from '../../assets/Utube.svg';
import CombinedLink from '../CombinedLink';
import { useNavStyles } from './style';
import clsx from 'clsx';
import { ImageNavItem, NavItem } from './DesktopDetailedCat';
import LogoLink from './LogoLink';
import {SearchIcon, XIcon} from '@heroicons/react/outline';
import { useDarkMode } from '@/lib/hooks/useDarkModeHook';
import { CompanyFooterDescription, FancyCtLogo } from '../FocusLayout/Footer';

interface MobileDrawerProps {
  global: Global;
  user?: SessionUser;
  items: GlobalNavigationBarDynamicZone[];
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  handleOpenDialog: () => void;
  isDrawerOpen: boolean;
}
const DrawerItem = (props: { item: GlobalNavigationBarDynamicZone }) => {
  const { item } = props;
  const url = useMemo(() => item.url ?? (item.category && `/${item.category.slug}`) ?? (item.article_tag && `/tag/${item.article_tag.slug}`), [item])
  const title = useMemo(() => item.title ?? (item.category && item.category.name) ?? (item.article_tag && item.article_tag.name) ?? "N/A", [item])
  const displayElement = useMemo(() => <ListItem {...(url ? { button: true as any } : {})} className={clsx("relative")}>
    <ListItemText primary={title} />
    {item.highlight &&
      <span className="flex h-3 w-3 absolute left-2 top-2">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
      </span>
    }
  </ListItem>, [item.highlight, title, url]);
  return url ? <CombinedLink href={url}>
    {displayElement}
  </CombinedLink> :
    displayElement
}

const MobileDrawer = (props: MobileDrawerProps) => {
  const theme = useTheme();
  const { classes, cx } = useNavStyles();
  const { global, user, items, toggleDrawer, handleOpenDialog, isDrawerOpen } = props;

  const {isDarkMode}=useDarkMode()
  const mobileDrawerLinksList = useMemo(() => {
    const links: { key: string; href: string; text: string }[] = [
      // { key: "about-us", href: "/about-us", text: "關於我們" },
      // { key: "advertise", href: "/advertise", text: "廣告查詢" },
      // { key: "find-job", href: "/find-job", text: "人才招聘" },
      // { key: "privacy", href: "/privacy", text: "私隱聲明" },
      // { key: "terms", href: "/terms", text: "條款及細則" },
      // { key: "hotline", href: "/hotline", text: "爆料熱線" },
      // { key: "faq", href: "/plan#FAQ", text: "常見問題" },
      // { key: "plan", href: "/plan", text: "會員計劃" },
      // { key: "partner", href: "/member/partner", text: "合作商戶" },
    ];

    return (
      <>
        <div className={clsx(' text-main ',isDarkMode&&"bg-dark-main")} >
          <div className={clsx(" flex flex-row h-[3.25rem] lg:h-[3.5rem] items-center max-w-[100vw]",isDarkMode?"bg-dark-base-200 text-dark-content-100":"bg-main")}>
            
            <IconButton
              onClick={toggleDrawer(false)}
              size={"medium"}
              className=""
            >
              <XIcon className="w-10 h-10 text-white " />
            </IconButton>
            <div className="block lg:hidden h-full  ">
              <LogoLink global={global} mobile={true}/>
            </div>
            <div className='flex grow w-full justify-end'>
              {/* <div className="flex-grow">
              <IconButton
                onClick={handleOpenDialog}
                size={"small"}
                className="!p-0"
                aria-label="搜尋"
              >
                <SearchSvg
                  className={cx(classes.svgColor, "h-12 w-12")}
                  display={"inline-block"}
                  preserveAspectRatio="xMidYMax slice"
                ></SearchSvg>
              </IconButton>
            </div> */}
              {user != null ? (
                <>
                  <CombinedLink href="/auth/account/info">
                    <Button className={classes.menuLogin}>個人檔案</Button>
                  </CombinedLink>
                  <CombinedLink href="/auth/logout">
                    <Button className={classes.menuLogin}>登出</Button>
                  </CombinedLink>
                </>
              ) : (
                <>
                  {global?.enabledLogin && (
                    <CombinedLink href="/auth/login">
                      <Button className={classes.menuLogin}>登入</Button>
                    </CombinedLink>
                  )}
                  {global?.enabledRegister && (
                    <CombinedLink href="/auth/register">
                      <Button className={classes.menuLogin}>註冊</Button>
                    </CombinedLink>
                  )}
                </>
              )}
            </div>
          </div>
          <CombinedLink href="/search" className={clsx(`gap-2 p-2 flex items-center m-2 rounded-md`,
            isDarkMode?"dark:bg-dark-base-200 text-dark-content-100":"bg-gray-300  text-white")}>
            <SearchIcon className='w-5 h-5'/>
            <span>搜尋文章</span>
          </CombinedLink>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-4 pb-4">
            {
              global?.expandedNavigationBar?.map((it: any, idx) => it.__component == "nav.category-nav-item" ?
                <NavItem item={it} className={clsx("  text-black ",isDarkMode&&"text-white")} key={idx}/> :
                it.__component == "nav.image-nav-item" ?
                  <ImageNavItem item={it} key={idx}/> :
                  <></>)
            }

          </div>
          <List sx={{ marginTop: "auto" }}>
            <Divider className={classes.DividerColor} />
          </List>

          {links.map((link) => (
            <CombinedLink
              href={link.href}
              key={`mobile-drawer-link-${link.key}`}
            >
              <ListItem button key={link.key}>
                <span className=' text-sm border'>{link.text}</span>
                {/* <ListItemText primary={link.text} className='text-xs border' variant="h6" /> */}
              </ListItem>
            </CombinedLink>
          ))}
          <div className="relative ">
            <div className="flex ">
              <CombinedLink href="https://www.facebook.com/channelchk2.0/">
                <FbSvg
                  className={cx("h-12 w-12")}
                  fill={theme.palette.secondary.main}
                  display={"inline-block"}
                  preserveAspectRatio="xMidYMax slice"
                ></FbSvg>
              </CombinedLink>
              <CombinedLink href="https://www.instagram.com/channelchk/">
                <IgSvg
                  className={cx("h-12 w-12")}
                  fill={theme.palette.secondary.main}
                  display={"inline-block"}
                  preserveAspectRatio="xMidYMax slice"
                ></IgSvg>
              </CombinedLink>
              <CombinedLink href="https://www.youtube.com/channel/UCQRdJCdUVkXYPvWZ6_Omkag">
                <YtSvg
                  className={cx("h-12 w-12")}
                  fill={theme.palette.secondary.main}
                  display={"inline-block"}
                  preserveAspectRatio="xMidYMax slice"
                ></YtSvg>
              </CombinedLink>
            </div>
          </div>
          <CompanyFooterDescription/>
          <FancyCtLogo />
        </div>
      </>
    );
  }, [items, classes.DividerColor, classes.menuLogin, cx, global?.enabledLogin, global?.enabledRegister, handleOpenDialog, theme.palette.secondary.main, user]);



  return (
    <Drawer
      anchor={"left"}
      open={isDrawerOpen}
      onClose={toggleDrawer(false)}
      classes={{ paper: "White" }}
      className=""
    >
      <div className={classes.list} role="presentation">
        {mobileDrawerLinksList}
      </div>
    </Drawer>
  );
}

export default MobileDrawer;