import {
  BoxProps,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NextImage from "next/legacy/image";
import * as React from "react";
import { makeStyles } from "tss-react/mui";
import Image, { ImageProps } from "next/legacy/image";
import { ImageFallback } from "../ImageFallback";
import { useGlobal } from "@/lib/hooks/GlobalHook";
import ctLogo from "public/images/ct-logo.png";

import { getStrapiMedia, toNextImageSrc } from "@/lib/media";
import { Global } from "@/lib/remoteGraphQL";
import CombinedLink from "@/components/CombinedLink";
import {
  appStoreUrl,
  googlePlayUrl,
  fbUrl,
  youtubeUrl,
  igUrl,enableApp
} from "@/lib/constants/appDownload";

//icon
import { ReactComponent as CCLogoIcon } from "@/assets/focusFooter/cc-logo.svg";
import { ReactComponent as FbIcon } from "@/assets/focusFooter/fb.svg";
import { ReactComponent as IgIcon } from "@/assets/focusFooter/ig.svg";
import { ReactComponent as YtIcon } from "@/assets/focusFooter/yt.svg";
import GooglePlayIcon from "@/assets/appIntro/google_play.png";
import ShareIAppStoreIcon from "@/assets/appIntro/app_store.png";

export const CompanyFooterDescription = () => {
  const { global } = useGlobal();
  return <div className="flex flex-col  md:flex-row   items-center  self-end grow"><div className="w-10 mb-2 md:mb-0 md:mr-3">
    <CCLogoIcon />
  </div>
  <div className="grow text-sm md:text-base">
    {global?.footerDescription?.split("\n")?.map((it, idx) => (
      <span
        className=" block text-gray-500 text-xs  leading-6 text-center md:text-start"
        key={idx}
      >
        {it}
      </span>
    ))}
  </div></div>
}

export const FancyCtLogo = ()=>{
  return    <a className="flex flex-col justify-center items-center my-4 md:my-0  group select-none" href="https://cooltechsol.com">
    <span className="font-mono  font-black text-center text-[10px] leading-none ct-fancy-text">Powered by</span>

    <div className="w-[100px] h-[20px] relative ">

      <div className="absolute left-0 top-0 right-0 bottom-0" style={{
        WebkitMaskImage: `url(${toNextImageSrc(ctLogo.src)})`,
        maskImage:`url(${toNextImageSrc(ctLogo.src)})`,
        WebkitMaskSize:"100px",
        maskSize:"100px"
      }}>
        <div className="ct-fancy-bg w-full h-full"></div>
      </div>
    </div>
  </a>
}

export const FooterInfo: React.FC = () => {
  const { global } = useGlobal();
  return (
    <div className=" w-full    flex flex-col  md:flex-row   items-center  self-end  px-0 md:px-1 ">
      <CompanyFooterDescription />
      <FancyCtLogo/>
    </div>
  );
};
export const SnsPromo: React.FC = () => {
  const { global } = useGlobal();
  return (
    <div className=" flex flex-col md:flex-row   items-center  gap-4 justify-center  w-full md:w-auto ">
      <div className=" text-sm text-gray-500">追蹤 {global.siteName}</div>
      <div className=" flex flex-row  gap-4 flex-wrap lg:border-r border-gray-500 lg:pr-4 ">
        <CombinedLink
          href={fbUrl}
          target={"_blank"}
          className="rounded-full bg-gray-500"
          aria-label="Facebook"
        >
          <FbIcon />
        </CombinedLink>
        <CombinedLink
          href={igUrl}
          target={"_blank"}
          className="rounded-full bg-gray-500"
          aria-label="Instagram"
        >
          <IgIcon />
        </CombinedLink>
        <CombinedLink
          href={youtubeUrl}
          target={"_blank"}
          className="rounded-full bg-gray-500"
          aria-label="Youtube"
        >
          <YtIcon />
        </CombinedLink>
      </div>
    </div>
  );
};

export const AppPromo: React.FC = () => {
  const { global } = useGlobal();
  const getUrlWithUtm = (urlStr:string)=>{
    const url = new URL(urlStr);
    url.searchParams.set("utm_source", "web");
    url.searchParams.set("utm_medium", "ccapppage");
    return url.toString();
  }
  return (
    <div className="flex flex-col md:flex-row items-center  justify-center  w-full md:w-auto gap-4 flex-wrap">
      <div className=" text-sm text-gray-500">下載 {global.siteName} App</div>
      <div className="flex  flex-row gap-4">
        <CombinedLink
          href={getUrlWithUtm(appStoreUrl)}
          target={"_blank"}
          className="relative h-10 w-[6.6rem]"
        >
          <ImageFallback
            layout="fill"
            objectFit="contain"
            priority={true}
            src={ShareIAppStoreIcon}
            className="  cursor-pointer hover:opacity-90"
          />
        </CombinedLink>
        <CombinedLink
          href={getUrlWithUtm(googlePlayUrl)}
          target={"_blank"}
          className="relative h-10 w-[8.5rem]"
        >
          <ImageFallback
            layout="fill"
            objectFit="contain"
            priority={true}
            src={GooglePlayIcon}
            className=" cursor-pointer hover:opacity-90"
          />
        </CombinedLink>
      </div>
    </div>
  );
};

export const FocusFooter: React.FC = () => {
  const { global } = useGlobal();
  return (
    <div className="h-full  w-full ">
      <div className="  max-w-[20.5rem] mx-auto   md:max-w-[48rem] lg:max-w-[60rem] 2xl:max-w-[96rem]   py-7   flex   flex-col-reverse  md:mx-auto  items-center   justify-between flex-wrap gap-4 ">
        <FooterInfo />
        <div className=" max-w-4xl lg:max-w-full  w-full   justify-center lg:justify-center  2xl:justify-start 2xl:w-auto flex flex-col md:flex-row  gap-4 flex-wrap  items-start  border-b  border-gray-500 2xl:border-none pb-12 md:pb-4 2xl:pb-0 ">
          <SnsPromo />
          {enableApp && <AppPromo />}
        </div>
      </div>
    </div>
  );
};
