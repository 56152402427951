import { Link, LinkProps } from "@mui/material";
import React from "react";
import NextjsLink, { LinkProps as NextjsLinkProps } from "next/link";
const CombinedLink = (props: LinkProps & NextjsLinkProps & {children?: React.ReactNode})=>{
  
  const { onClick, ...nextjsLinkPropsRest } = props;
  const nextjsLinkProps = nextjsLinkPropsRest as NextjsLinkProps;

  // filter properties and pass the rest to linkProps
  const { scroll,prefetch, ...rest } = props;
  const linkProps = { underline:"none", color:"inherit", ...rest } as LinkProps;

  return (
    <NextjsLink scroll={true} {...nextjsLinkProps} legacyBehavior>
      <Link {...linkProps}>
        {props.children}
      </Link>
    </NextjsLink>
  );
}
export default CombinedLink;