import { ApolloClient, InMemoryCache, NormalizedCacheObject, DocumentNode, OperationVariables } from '@apollo/client';

export function getStrapiURL(path = "") {
  if(path.startsWith("http"))
    return path;
  return `${
    process.env.NEXT_PUBLIC_STRAPI_API_URL || "http://localhost:1337"
  }${path}`
}

export function getRedeemURL(path = "") {
  if(path.startsWith("http"))
    return path;
  return `${
    process.env.NEXT_PUBLIC_REDEEM_URL || "http://localhost:3000"
  }/api/external${path}`
}


// Helper to make requests to Strapi
export async function fetchAPIRaw(path:string, init?: RequestInit): Promise<Response> {
  init = init??{};
  init.headers =  {
    ...init.headers,
    Accept: "application/json",
  }
  const requestUrl = getStrapiURL(path)
  const response = await fetch(requestUrl, init)
  if(!response.ok)
    throw response;
  return response;
}

// Helper to make requests to Redeem
export async function fetchRedeemAPIRaw(path:string, init?: RequestInit): Promise<Response> {
  init = init??{};
  init.headers =  {
    ...init.headers,
    Accept: "application/json",
  }
  const requestUrl = getRedeemURL(path)
  const response = await fetch(requestUrl, init)
  if(!response.ok)
    throw response;
  return response;
}



// Helper to make requests to Strapi
export async function fetchAPI<T>(path:string, init?: RequestInit): Promise<T> {
  const data = (await fetchAPIRaw(path,init)).json()
  return data;
}

// Helper to make requests to Redeem
export async function fetchRedeemAPI<T>(path:string, init?: RequestInit): Promise<T> {
  const data = (await fetchRedeemAPIRaw(path,init)).json()
  return data;
}



// Helper to make requests to Strapi
export async function fetchAPIWithBody<T>(path:string, body:any, init?: RequestInit): Promise<T> {
  init = init??{};
  init.headers =  {
    ...init.headers,
    "Content-Type": "application/json"
  }
  init.body = JSON.stringify(body);
  return fetchAPI<T>(path, init);
}
// Helper to make requests to Strapi
export async function fetchAPIWithBodyRaw(path:string, body:any, init?: RequestInit): Promise<Response> {
  init = init??{};
  init.headers =  {
    ...init.headers,
    "Content-Type": "application/json"
  }
  init.body = JSON.stringify(body);
  return fetchAPIRaw(path, init);
}

// Helper to make requests to Strapi
export async function authFetchAPI<T>(path:string,accessToken?:string| null, init?: RequestInit): Promise<T> {
  init = init??{};
  init.headers =  {
    ...init.headers,
    "Authorization": "Bearer " + accessToken
  }
  return fetchAPI<T>(path, init);
}


// Helper to make requests to Redeem
export async function authRedeemFetchAPI<T>(path:string,accessToken?:string| null, init?: RequestInit): Promise<T> {
  init = init??{};
  init.headers =  {
    ...init.headers,
    "Authorization": "Bearer " + accessToken
  }
  return fetchRedeemAPI<T>(path, init)
}


export async function authFetchAPIRaw(path:string,accessToken?:string| null, init?: RequestInit): Promise<Response> {
  init = init??{};
  init.headers =  {
    ...init.headers,
    "Authorization": "Bearer " + accessToken
  }
  return fetchAPIRaw(path, init);
}
// Helper to make requests to Strapi
export async function authFetchAPIWithBody<T>(path:string, body:any,accessToken?:string| null, init?: RequestInit): Promise<T> {
  init = init??{};
  init.headers =  {
    ...init.headers,
    "Content-Type": "application/json"
  }
  init.body = JSON.stringify(body);
  return authFetchAPI<T>(path,accessToken, init);
}


// Helper to make POST requests to Strapi
export function getGraphQLClient(headers?:Record<string, string>): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    uri: getStrapiURL("/graphql"),
    cache:  new InMemoryCache(),
    headers:headers,
  });
}

export async function fetchGraphQL<T, TVariables extends OperationVariables= OperationVariables>(gql: DocumentNode, variables?:  TVariables,  headers?:Record<string, string>): Promise<T>{
  const graphqlClient = getGraphQLClient(headers);
  const { data } = await graphqlClient.query<T>({
    query: gql,
    variables: variables
  });
  return data;
}

export async function fetchMutateGraphQL<T, TVariables extends OperationVariables= OperationVariables>(gql: DocumentNode, variables?:  TVariables, headers?:Record<string, string>): Promise<T>{
  const graphqlClient = getGraphQLClient(headers);
  const { data } = await graphqlClient.mutate<T>({
    mutation:gql,
    variables: variables
  });
  return data as T;
}


