import React, { useMemo, useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { useGlobal } from "@/lib/hooks/GlobalHook";
import { useUser } from "@/lib/hooks/UserHook";
import { getStrapiURL } from "@/lib/api";

//icon
import CrownIcon from "@/assets/avator-crown.svg";
import PeanutIcon from "@/assets/avator-peanut.svg";

export interface useProfileImageProps {
  userId?: string;
  isPaidMember?: boolean;
}

export const useProfileImage = (props: useProfileImageProps) => {
  const { userId, isPaidMember } = props;
  const { data } = useSession();
  const { memberFunction } = useGlobal();

  const { updateCurrentTimeHandler, updateCurrentTime } = useUser();

  const profileFallbackSrc = useMemo(() => {
    return isPaidMember ? CrownIcon : PeanutIcon;
  }, [isPaidMember]);

  const profileImageSrc = useMemo(() => {
    return (
      getStrapiURL(
        `/users/getIcon?userId=${
          userId ?? data?.user.id
        }&_t=${updateCurrentTime}`
      ) ?? null
    );
  }, [userId, data, updateCurrentTime]);

  const memberCardDesign = useMemo(
    () =>
      (isPaidMember
        ? memberFunction?.paidMemberCardDesign
        : memberFunction?.normalCardDesign) ?? null,
    [isPaidMember, memberFunction]
  );

  return { profileFallbackSrc, profileImageSrc, memberCardDesign };
};
