import { createContext, useContext, useEffect, useState } from 'react';

import { notificationsDb as db } from '../dexie/notificationsDb';


const DexieContext = createContext<{
  isSupportIndexDB: boolean;
}>({
  isSupportIndexDB:false
});


// provide method to post msg to wb
export const DexieProvider = (props: { children?: React.ReactNode }) => {
  const [isSupportIndexDB, setIsSupportIndexDB] = useState(false);

  useEffect(() => {
    db.open()
      .then(() => {
        setIsSupportIndexDB(true);
      })
      .catch("MissingApiError", function (e) {
        console.error("MissingApiError");
        setIsSupportIndexDB(false);
        // Show message to user that indexedDB is missing
      })
      .catch(function (e) {
        // Show e.message to user
      });
  }, []);

  return (
    <DexieContext.Provider value={{ isSupportIndexDB }}>
      {props.children}
    </DexieContext.Provider>
  );
};

const useDexie = () => useContext(DexieContext);

export default useDexie;