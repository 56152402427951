import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Cancel} from '@mui/icons-material';
import React, { useMemo } from "react"
import { useLoginRegMsg } from "@/lib/hooks/LoginRegMsgHook";
import CombinedLink from "./CombinedLink";
import { useRouter } from "next/router";
// const useStyles = makeStyles((theme: Theme) =>
//   ({
//     DiaLogButton:{
//       justifyContent:'center'
//     },
//   }),
// );

export const ShowLoginRegMsgDialog = (props:any)=>{
  const {isOpenDialog, OpenDialogHandler, redirectUrl} = useLoginRegMsg();
  const router =  useRouter();
  const isContentRedirect = useMemo(()=> !!redirectUrl, [redirectUrl]);
  const componentRedirectUrl = useMemo(() => `/auth/login?return-url=${router.asPath}`, [router]);
  const contentRedirectUrl = useMemo(() => `/auth/login?return-url=${redirectUrl}` , [redirectUrl]);

  // Dialog part:

  return(
    <Box>
      {/* <Button onClick={()=>{handleClickOpen();}}>
        <Typography className={classes.typography} variant="button" >檢舉留言</Typography>
      </Button> */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpenDialog!}
        onClose={()=>{OpenDialogHandler()}}
        scroll="body"
      >
        <Box display="flex"> 
          <Box flexGrow={1}><DialogTitle></DialogTitle></Box>
          <Box><IconButton onClick={()=>OpenDialogHandler()} size={'medium'}><Cancel /></IconButton></Box>
        </Box> 
        <DialogContent sx={{minHeight:"20vh"}}>
          <DialogContentText>
            <Box 
              className="text-center"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>請先免費註冊或登入帳號</Typography>
              <Box className="mt-4">
                {/* <CombinedLink href={`/auth/login${redirectUrl? `?return-url=${redirectUrl}`:""}`}> */}
                <CombinedLink href={isContentRedirect?contentRedirectUrl:componentRedirectUrl}>
                  <Button fullWidth  className="!justify-center !bg-channelY text-white">登入</Button>
                </CombinedLink>
              </Box>
              <Box className="mt-4">
                <CombinedLink href="/auth/register">
                  <Button fullWidth  className="!justify-center !bg-main">免費註冊</Button>
                </CombinedLink>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  )
}