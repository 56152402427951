import { useLiveQuery } from "dexie-react-hooks";
import { onMessage } from "firebase/messaging";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { debounce } from "lodash";

import { notificationsDb as db } from "../dexie/notificationsDb";
import { WebNotification } from "../firebase/utils";
import useDexie from "./DexieHook";
import useFirebase from "./FirebaseHook";
import useWorkbox from "./WorkboxHook";
import { FcmTopic } from "@/lib/firebase/utils";
const NotificationContext = createContext<{
  notifications?: WebNotification[];
  hasNewNotifications?: boolean;
  isNewNotificationsShown?: boolean;
  isSupportNotification?: boolean;

  toogleNotifSwitch?: () => void;
  isOpenNotification?: boolean;
  isSubscribedArticle?: boolean;
  isSubscribedManual?: boolean;
  isSubscribedUser?: boolean;

  toogleSubscrubedTopic?: (topic: FcmTopic) => void;
    }>({});

export const NotificationProvider = (props: { children?: React.ReactNode }) => {
  const { msgWb } = useWorkbox();
  const { isSupportIndexDB } = useDexie();
  const {
    firebaseMessaging,
    fcmToken,
    notificationPermission,
  } = useFirebase();
  const [isSupportNotification, setIsSupportNotification] = useState(false);

  //文章 資訊 會員  ["article", "manual", "user", "developer-001"]

  useEffect(() => {
    // Get Permissions
    if (!isSupportIndexDB) return;

    if (!("Notification" in window)) {
      setIsSupportNotification(false);
      return;
    } else {
      setIsSupportNotification(true);
    }

    db.status
      .put({ key: "notificationPermission", value: Notification.permission })
      .catch(console.error);
    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((notificationPerm) => {
          notificationPerm.onchange = () => {
            db.status
              .put({
                key: "notificationPermission",
                value:
                  notificationPerm.state === "prompt"
                    ? "default"
                    : notificationPerm.state,
              })
              .catch(console.error);
          };
        });
    }
  }, [isSupportIndexDB]);

  // <----------------------- INIT - onMessage ----------------------->
  useEffect(() => {
    // Handle Incoming Notifications
    if (!firebaseMessaging) return;
    onMessage(firebaseMessaging, async (payload) => {
      if (!payload.data || !payload.messageId || !msgWb) return;
      msgWb("newWindowNotification", {
        notification: {
          data: payload.data,
          isRead: false,
          messageId: payload.messageId,
        },
      });
    });
  }, [firebaseMessaging, msgWb]);

  // <----------------------- GET - Notifications - All Notifications ----------------------->
  const notifications = useLiveQuery<
    WebNotification[] | undefined
  >(async () => {
    if (!isSupportIndexDB) return undefined;
    const notifs = await db.notifications
      .orderBy("id")
      .reverse()
      .toArray()
      .catch(console.error);
    return notifs ?? undefined;
  }, [isSupportIndexDB]);

  // <----------------------- GET - Status - Has New Notifications ----------------------->
  const hasNewNotifications = useLiveQuery<boolean | undefined>(async () => {
    if (!isSupportIndexDB) return undefined;
    const has = await db.status
      .get({ key: "hasNewNotifications" })
      .catch(console.error);
    return (has?.value as boolean) ?? undefined;
  }, [isSupportIndexDB]);

  // <----------------------- GET - Status - Is New Notifications Shown ----------------------->
  const isNewNotificationsShown = useLiveQuery<
    boolean | undefined
  >(async () => {
    if (!isSupportIndexDB) return undefined;
    const is = await db.status
      .get({ key: "isNewNotificationsShown" })
      .catch(console.error);
    return (is?.value as boolean) ?? undefined;
  }, [isSupportIndexDB]);

  // <----------------------- GET - Status - IsSubscribed ----------------------->
  const isOpenNotification = useLiveQuery<boolean | undefined>(async () => {
    if (!isSupportIndexDB) return undefined;
    const is = await db.status
      .get({ key: "isOpenNotification" })
      .catch(console.error);
    return (is?.value as boolean) ?? undefined;
  }, [isSupportIndexDB]);

  // <----------------------- GET - Status - Is sub article topic ----------------------->
  const isSubscribedArticle = useLiveQuery<boolean | undefined>(async () => {
    if (!isSupportIndexDB) return false;
    const is = await db.status
      .get({ key: "isSubscribedArticle" })
      .catch(console.error);
    return (is?.value as boolean) ?? undefined;
  }, [isSupportIndexDB]);

  // <----------------------- GET - Status - Is sub manual topic ----------------------->
  const isSubscribedManual = useLiveQuery<boolean | undefined>(async () => {
    if (!isSupportIndexDB) return false;
    const is = await db.status
      .get({ key: "isSubscribedManual" })
      .catch(console.error);
    return (is?.value as boolean) ?? undefined;
  }, [isSupportIndexDB]);

  // <----------------------- GET - Status - Is sub user topic ----------------------->
  const isSubscribedUser = useLiveQuery<boolean | undefined>(async () => {
    if (!isSupportIndexDB) return false;
    const is = await db.status
      .get({ key: "isSubscribedUser" })
      .catch(console.error);
    return (is?.value as boolean) ?? undefined;
  }, [isSupportIndexDB]);

  // <----------------------- METHOD - Notification perference ----------------------->

  const toogleNotifSwitch =debounce(useCallback(() => {

    switch (notificationPermission) {
    case "default":
      Notification.requestPermission().then((permission) => {
        db.status
          .put({ key: "notificationPermission", value: permission })
          .catch(console.error);
        if (permission === "granted") {
          changeSwitchHandle();
        }
      });
      break;
    case "denied":
      break;
    case "granted":
      changeSwitchHandle();
      break;
    default:
      break;
    }
  }, [notificationPermission, isOpenNotification]),300)

  // <----------------------- useEffect - close last switch logic ----------------------->
  useEffect(() => {
    if (
      isOpenNotification &&
      !isSubscribedArticle &&
      !isSubscribedManual &&
      !isSubscribedUser
    ) {
      toogleNotifSwitch();
    }
  }, [isSubscribedArticle, isSubscribedManual, isSubscribedUser]);

  useEffect(() => {
    if (!msgWb || isOpenNotification === undefined || !fcmToken) return;
    isOpenNotification!==undefined && changeAllTopic();

  }, [isOpenNotification]);

  useEffect(() => {
    if (!msgWb || !fcmToken) return;
    msgWb(isSubscribedArticle ? "sub" : "unsub", {
      registrationToken: fcmToken,
      topic: "article",
    });
  }, [isSubscribedArticle]);

  useEffect(() => {
    if (!msgWb || !fcmToken) return;
    msgWb(isSubscribedManual ? "sub" : "unsub", {
      registrationToken: fcmToken,
      topic: "manual",
    });
  }, [isSubscribedManual]);

  useEffect(() => {
    if (!msgWb || !fcmToken) return;
    msgWb(isSubscribedUser ? "sub" : "unsub", {
      registrationToken: fcmToken,
      topic: "user",
    });
  }, [isSubscribedUser]);


  // <----------------------- method -  change switch ----------------------->
  const changeSwitchHandle = () => {
    db.status
      .put({ key: "isOpenNotification", value: !isOpenNotification })
      .catch(console.error);
  };


  // <----------------------- method -  sub/unsub all topic ----------------------->
  const changeAllTopic = async () => {
    if (!msgWb || isOpenNotification == undefined || !fcmToken) return;
    await db.status
      .put({ key: "isSubscribedArticle", value: isOpenNotification })
      .catch(console.error),
    await db.status
      .put({ key: "isSubscribedUser", value: isOpenNotification })
      .catch(console.error),
    await db.status
      .put({ key: "isSubscribedManual", value: isOpenNotification })
      .catch(console.error);
  };



  // <----------------------- method -  sub/unsub  topic ----------------------->
  const toogleSubscrubedTopic = debounce((topic: FcmTopic) => {
    if (!msgWb || !fcmToken) return;

    if (isOpenNotification) {
      if (topic === "article") {
        db.status
          .put({ key: "isSubscribedArticle", value: !isSubscribedArticle })
          .catch(console.error);
      }
      if (topic === "manual") {
        db.status
          .put({ key: "isSubscribedManual", value: !isSubscribedManual })
          .catch(console.error);
      }
      if (topic === "user") {
        db.status
          .put({ key: "isSubscribedUser", value: !isSubscribedUser })
          .catch(console.error);
      }
    }
  }, 300);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        hasNewNotifications,
        isNewNotificationsShown,
        isSupportNotification,

        toogleNotifSwitch,

        isOpenNotification,
        isSubscribedArticle,
        isSubscribedManual,
        isSubscribedUser,

        toogleSubscrubedTopic,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);

export default useNotification;
