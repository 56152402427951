import React ,{useMemo,useState,useRef,RefObject, useEffect} from "react"
import { useSession } from "next-auth/react";
import clsx from "clsx"

//hook
import { useOutsideAlerter } from "@/lib/hooks/useOutsideAlerter";
//components
import { CustomAvatar } from "../CustomAvatar";
import {DropdownMenu} from "./DropdownMenu"
//icon
import { ReactComponent as UserIcon } from "@/assets/user-account-yellow.svg";
import { ReactComponent as SmallUserIcon } from "@/assets/user-account-yellow-small.svg";

export const NavProfileButton =()=>{

  const { data: session, status } = useSession();
  const user = useMemo(() => session?.user, [session]);

  const wrapperRef = useRef<React.RefObject<HTMLDivElement>>(null);


  const [showDropdownMenu,setShowDropdownMenu]=useState(false)

  useEffect(()=>{
    if(showDropdownMenu&& window.innerWidth <= 1023){
      document.body.style.overflow = "hidden"
    }else{
      document.body.style.overflow = "auto"
    }
  },[showDropdownMenu])

  useOutsideAlerter(wrapperRef, ()=>setShowDropdownMenu(false));


  return(
    <div className={clsx("   relative ")} 
      ref={wrapperRef as unknown as RefObject<HTMLDivElement>}
    >
      <div className={clsx("  z-10  relative  cursor-pointer w-full h-full  ")} 
        onClick={(e)=> {
          e.preventDefault()
          setShowDropdownMenu(!showDropdownMenu)}}
      >
        {user?<div className={clsx(`relative   flex items-center justify-center
        active:scale-90 transition-all   lg:w-10 lg:h-10 w-7 h-7  rounded-full border-2 border-primary-500 
        `)}><CustomAvatar userId={user.id} /></div>:
          <div className=" rounded-full overflow-hidden transition-all active:scale-90 ">
            <UserIcon  className="hidden lg:block w-9 h-9 text-main dark:text-primary-500"/>
            <SmallUserIcon className="  lg:hidden  text-main dark:text-primary-500"  />
          </div>
        }
      </div>
      <DropdownMenu isOpen={showDropdownMenu} close={()=>setShowDropdownMenu(false)} />
    </div>
  )
}