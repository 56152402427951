import React, { useState } from "react"

import Modal from 'react-modal';
import clsx from "clsx"
import {useDarkMode} from "@/lib/hooks/useDarkModeHook";


 type CustomDialogProps={
  isOpen:boolean
  close:()=>void
  children: React.ReactNode
 }

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius:"10px",
    padding:"0px",
    background:"transparent"
  },
  overlay:{
    backgroundColor:"rgba(0, 0, 0, 0.5)",
    zIndex:"999",
  }
};

export const CustomDialog= (props:CustomDialogProps  ) =>{
  const { isOpen,close ,children} = props;

  const {isDarkMode}=useDarkMode()

  const handleClose = () => {
    close()
  };


  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={handleClose}
      ariaHideApp={false}
      overlayClassName={""}
      contentElement={
        (props, children) => <div {...props} className={clsx(" !border-none bg-white",isDarkMode&&"!bg-dark-base-200")}>{children}</div>
      }
    >      
      {children}
    </Modal>
  )
}