import { useGlobal } from "@/lib/hooks/GlobalHook";
import {
  Global,
  GlobalExpandedNavigationBarDynamicZone,
  GlobalNavigationBarDynamicZone,
} from "@/lib/remoteGraphQL";
import { Menu, Popover, Transition } from "@headlessui/react";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx"
import CombinedLink from "../CombinedLink";
// import MarqueeFeed from '../marquee-feed/MarqueeFeed';
import DesktopCat from "./DesktopCat";
import DesktopDetailedCat from "./DesktopDetailedCat";
import DesktopRightBlock from "./DesktopRightBlock";
import DesktopShowMoreCat from "./DesktopShowMoreCat";
import ElevationScroll from "./ElevationScroll";
import LogoLink from "./LogoLink";
import MobileDrawer from "./MobileDrawer";
import SimpleDialog from "./SimpleDialog";
import { useNavStyles } from "./style";

//components
import {NavProfileButton} from "./NavProfileButton"
import { useDarkMode } from "@/lib/hooks/useDarkModeHook";
const MarqueeFeed = dynamic(
  () => import("../marquee-feed/MarqueeFeedSection"),
  { ssr: false }
);

const Nav = () => {
  const { global } = useGlobal();
  const categories = (global?.navCategories || [])?.map((it) => it!);
  const theme = useTheme();
  const router = useRouter();
  const { classes, cx } = useNavStyles();
  const { data: session, status } = useSession();

  const {isDarkMode}=useDarkMode()
  // const { updateCurrentTime } = useUser();

  const user = useMemo(() => session?.user, [session]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };

  // const [displayShowMore, setDisplayShowMore] = useState(false);
  // const desktopCatContainerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const checkShowMore = () => {
  //     const container = desktopCatContainerRef.current;
  //     if (!container) return;
  //     container && setDisplayShowMore(container.scrollWidth > container.clientWidth);
  //   }
  //   checkShowMore();
  //   window.addEventListener("resize", checkShowMore);
  //   return () => window.removeEventListener("resize", checkShowMore);
  // }, [desktopCatContainerRef]);

  // const returnUrl = useMemo(() => router.asPath, [router]);
  // const {OpenDialogHandler, RedirectUrlHandler} = useLoginRegMsg();
  // const onCheckUserLogin = () =>{
  //     RedirectUrlHandler(returnUrl);
  //     OpenDialogHandler();
  // }
  useEffect(()=>{
    setIsDrawerOpen(false)
  }, [router.asPath])

  return (
    <header className="shrink-0 " >
      <SimpleDialog open={isDialogOpen} onClose={handleCloseDialog} />

      <ElevationScroll>
        <AppBar
          position="fixed"
          className={clsx(
            classes.grow,
            `my-0 py-0  block   h-12 !bg-gray-50 
            after:border-primary-500 after:border-b-[5px] 
            shadow-none after:w-full after:content-[''] after:absolute after:bottom-0 z-20 dark:!bg-dark-main`
          )}
        >
          <div className="flex justify-between items-center lg:container lg:mx-auto h-[calc(100%-5px)] ">
            <div className="hidden lg:block">
              <LogoLink global={global} />
            </div>
            {/* <---------------------- DESKTOP ---------------------->  */}

            <DesktopDetailedCat
              items={
                (global?.expandedNavigationBar as GlobalExpandedNavigationBarDynamicZone[]) ??
                []
              }
            />
            <DesktopCat
              items={
                (global?.navigationBar as GlobalNavigationBarDynamicZone[]) ??
                []
              }
            />

            <DesktopRightBlock
              handleOpenDialog={handleOpenDialog}
              user={user}
              global={global}
            />

            {/* <-------------------------- MOBILE -------------------------->  */}
            <div
              className={cx(
                "absolute flex flex-row items-center w-full right-0 px-2 top-0 h-full max-mobileM:gap-1 gap-2 lg:hidden "
              )}
            >
              <IconButton
                onClick={toggleDrawer(true)}
                size={"medium"}
                className="h-full aspect-1"
              >
                <MenuIcon className="dark:fill-white" />
              </IconButton>
              <div className="block lg:hidden h-full ">
                <LogoLink global={global} mobile={true}/>
              </div>
              <div className="grow flex justify-end w-full  ">
              </div>

              <MobileDrawer
                global={global}
                user={user}
                items={
                  (global?.navigationBar as GlobalNavigationBarDynamicZone[]) ??
                  []
                }
                toggleDrawer={toggleDrawer}
                handleOpenDialog={handleOpenDialog}
                isDrawerOpen={isDrawerOpen}
              />
             
            </div>
            <div className="flex flex-row  items-center z-auto">
              <CombinedLink href={"/plan"} target={"_blank"} className="z-10 cursor-pointer"
              >
                <div className="lg:hidden   cursor-pointer z-auto bg-primary-500 h-6 flex items-center justify-center px-3 rounded-xl text-xs text-gray-600 dark:text-dark-content-300">
                立即訂閱</div>
              </CombinedLink>
              <div className="px-2 z-auto">
                <NavProfileButton  />
              </div>
            </div>
            <MarqueeFeed />
          </div>
        </AppBar>
      </ElevationScroll>
    </header>
  );
};

export default Nav;
