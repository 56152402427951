import { Global } from '@/lib/remoteGraphQL';
import { Button } from '@mui/material';
import { SessionUser } from 'next-auth';
import { useRouter } from 'next/router';
import React from 'react';
import { useNavStyles } from './style';

//components
import CombinedLink from '../CombinedLink';


//icon
import { ReactComponent as FbSvg } from '../../assets/FB.svg';
import { ReactComponent as IgSvg } from '../../assets/IG.svg';
import { ReactComponent as YtSvg } from '../../assets/Utube.svg';
import SearchIcon from '@heroicons/react/solid/SearchIcon'
interface DesktopRightBlockProps {
  handleOpenDialog: () => void;
  user: SessionUser | undefined;
  global: Global;
}
const DesktopRightBlock = (props: DesktopRightBlockProps) => {
  const { handleOpenDialog, user, global } = props;
  const { classes, cx } = useNavStyles();
  const router = useRouter();

  return (
    <div className={cx( "h-full lg:block hidden")}>
      <div className={cx( "flex flex-row items-center h-full")}>
        {router.pathname != "/search" && (
          <div className='2xl:w-40 h-8 bg-gray-80 dark:bg-dark-base-200 dark:text-dark-content-100   rounded-md text-gray-500 flex flex-row justify-start cursor-pointer px-4  m-0 items-center '
            onClick={handleOpenDialog}>
            <SearchIcon
              className={cx("h-[18px] w-[18px] mr-4")}
              display={"inline-block"}
            />
            <span className='text-sm  pr-4 '>搜尋文章</span>
          </div>
        )}

        <div>
          <CombinedLink
            href="https://www.facebook.com/channelchk2.0/"
            aria-label="Facebook"
          >
            <FbSvg
              className={cx(" relative top-[1px] h-10 w-10 fill-gray-500  dark:fill-white")}
              display={"inline-block"}
              preserveAspectRatio="xMidYMax slice"
            ></FbSvg>
          </CombinedLink>
        </div>

        <div>
          <CombinedLink
            href="https://www.instagram.com/channelchk/"
            aria-label="Instagram"
          >
            <IgSvg
              className={cx(" relative  h-10 w-10 fill-gray-500  dark:fill-white")}
              display={"inline-block"}
              preserveAspectRatio="xMidYMax slice"
            ></IgSvg>
          </CombinedLink>
        </div>

        <div>
          <CombinedLink
            href="https://www.youtube.com/@ChannelCHK"
            aria-label="Youtube"
          >
            <YtSvg
              className={cx("relative top-[1px] h-10 w-10 fill-gray-500  dark:fill-white")}
              display={"inline-block"}
              preserveAspectRatio="xMidYMax slice"
            ></YtSvg>
          </CombinedLink>
        </div>
      
        <div className=' flex flex-row  items-center h-full justify-center'  >
          <> {user==null && global?.enabledRegister && (
            <CombinedLink href="/auth/register">
              <div className={'relative bottom-[1px] px-2 text-lg justify-center items-center  text-gray-500 dark:text-white'}>註冊</div>
            </CombinedLink>
          )}
          </>
        </div>
      </div>
    </div>
  );
};

export default DesktopRightBlock;