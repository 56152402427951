import { Cancel } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { theme } from '../../pages/_app';
import { useNavStyles } from './style';
import {useDarkMode} from "@/lib/hooks/useDarkModeHook"
import clsx from "clsx"
import React from "react";
import Meilisearch from '../search/MeiliSearch';
interface SimpleDialogProps {
  open: boolean;
  //    selectedValue: string;
  onClose: () => void;
}



const SimpleDialog = (props: SimpleDialogProps)=>{
  const { onClose, open } = props;
  const { classes } = useNavStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const {isDarkMode}=useDarkMode()
  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value: string) => {
    onClose();
  };
  // eventName: string;
  // userToken?: string;
  // timestamp?: number;
  // index: string;
  // queryID: string;
  // objectIDs: string[];
  // positions: number[];

  // const Hitasd = (obj:{ hit:any }) => <p>{obj.hit.slug}</p>;



  // const HitWithInsights = connectHitInsights(aa)(Hit);

  // <Hits hitComponent={HitWithInsights} />;

  return (

    <Dialog max-width onClose={handleClose} open={open} maxWidth={"md"} fullWidth fullScreen={fullScreen} classes={{ paper: classes.DialogStyle }}>
      <DialogContent className={clsx(classes.scrollableDialog,"dark:bg-dark-base-200",isDarkMode&&"bg-dark-base-200 text-dark-content-100")}>
        <div className='flex flex-row'  >
          <Box flexGrow={1}><DialogTitle >文章搜尋</DialogTitle></Box>
          <Box><IconButton onClick={() => handleListItemClick('false')} size={'medium'}><Cancel  
            className={clsx('',isDarkMode&&"text-dark-content-100")}/></IconButton></Box>
        </div>
        <Meilisearch perPage={6} onHitClick={()=>{handleListItemClick('false')}}/>
      </DialogContent>
    </Dialog>

  );
}

export default SimpleDialog