import { GlobalProvider, useGlobal } from "@/lib/hooks/GlobalHook";
import { LoginRegMsgProvider } from "@/lib/hooks/LoginRegMsgHook";
import { UserProvider } from "@/lib/hooks/UserHook";
import { Theme, Toolbar } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useSession } from "next-auth/react";
import Head from "next/head";
import NextNprogress from 'nextjs-progressbar';
import React, { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from 'tss-react/mui';
import { getStrapiMedia } from "../lib/media";
import { ComponentSharedSeo, Global, MemberFunction } from "../lib/remoteGraphQL";
import BackToTopButton from "./BackToTopButton";
import Footer from "./Footer";
import Nav from "./Nav";
import {SelectAvatarProvider  } from"@/lib/hooks/useSelectAvatarHook";
import clsx from "clsx"
import { StaticHyperLink } from "./sections/StaticHyperLink";
import { ShowLoginRegMsgDialog } from "./ShowLoginRegMsgDialog";
import ReactGA from 'react-ga4'
import { MobileBottomBar } from "./MobileBottomBar";
import { enableApp } from "@/lib/constants/appDownload";
import {OpenAppButton} from "@/components/OpenAppButton"
import {SubscriptionBar} from "@/components/SubscriptionBar"
const useStyles = makeStyles()((theme: Theme) =>
  ({
    root :{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      // maxWidth: "100vw",
      // overflowX: "hidden",
      position:"relative",
    },
      
    content :{
      flex: "1 0 auto",
      // paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
      
    header: {
      flexShrink: 0,
    },
    footer: {
      flexShrink: 0,
    }
  }));

const Layout = (props: LayoutProps) => {
  const { children } = props;
  const {global, memberFunction} = useGlobal();
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const {data, status} = useSession();
  

  return (
    <UserProvider><LoginRegMsgProvider>
      <SelectAvatarProvider >
        {/* <NoSsr>  */} 
        <div className={clsx(classes.root," dark:bg-dark-main "  ,)}>
          <Head>  
            <link rel="apple-touch-icon" href={getStrapiMedia(global?.favicon)?.toString()} />
            <link rel="shortcut icon" href={getStrapiMedia(global?.favicon)?.toString()} />
          </Head>
          <Nav />
          <NextNprogress
            color={theme.palette.primary.main}
            startPosition={0.3}
            stopDelayMs={200}
            height={4}
            showOnShallow={true}
          />
          
          <SubscriptionBar />
          <OpenAppButton />
          <CookieConsent
            location="bottom"
            buttonText="同意"
            cookieName="channelCCookie"
            style={{ background: "#f2d960", color:"black" }}
            expires={150}
            flipButtons={true}
            enableDeclineButton
            declineButtonText="拒絕"
            buttonClasses="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary rounded-md px-4 py-2"
            declineButtonClasses="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary rounded-md px-4 py-2"
            disableButtonStyles={true}
            buttonStyle={{
              background:"#fdf9e7",
              color:"black",
              margin:"15px"
            }}
            declineButtonStyle={{
              background:"#edca22",
              color:"black",
              margin:"15px"
          
            }}
          >
        為了提升服務質素，我們會使用Cookie或其他類似技術來改善使用者的閱讀體驗。{" "}
            <span className="text-[10px]">如想了解更多, 請到此<a href="https://www.cookiesandyou.com/">網頁</a></span>
          </CookieConsent>
          <div className={cx(classes.content,"pt-16 md:pt-20 md:container md:mx-auto lg:px-auto  ")}>
            {/* <Container maxWidth="lg" className={clsx(classes.content,"pt-0 lg:pt-20")}> */}
            {children ?? <></>}
            <ToastContainer position='bottom-right'/>
            <ShowLoginRegMsgDialog />
            {/* </Container> */}
            {global?.staticBlocks?.map((it,idx)=><StaticHyperLink model={it} key={idx}/>)}
          </div>
          <Toolbar/>
          <>
            {/* <Footer global={global} className={cx(classes.footer, "fixed bottom-0 w-full hidden md:block  ")}/> */}
            {enableApp?
              <MobileBottomBar  global={global} />:<></>}
          </>
        </div>

        {/* </NoSsr>      */}   
      </SelectAvatarProvider>
    </LoginRegMsgProvider>
    </UserProvider>
  );
}

export type LayoutProps = {
  children?: React.ReactNode,
  global?: Global,
  memberFunction?: MemberFunction,
  seo?: ComponentSharedSeo
}
export default Layout
