import { getCorrespondingImage, getStrapiMedia } from "@/lib/media";
import { Maybe, UploadFile } from "@/lib/remoteGraphQL";
import { Dialog, DialogProps } from "@mui/material";
import NextImage from "next/legacy/image";
import { getStrapiURL } from "@/lib/api";
import { useUser } from "@/lib/hooks/UserHook";

import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { ImageFallback } from "../ImageFallback";
import { useGlobal } from "@/lib/hooks/GlobalHook";
import { useSession } from "next-auth/react";

import {CustomDialog} from "@/components/CustomDialog"
import { useDarkMode } from "@/lib/hooks/useDarkModeHook";

export const SelectPredefinedAvatarDialog = (
  props: {
    availableAvatar: Maybe<UploadFile>[];
    onAvatarSelected: (selected: UploadFile | null) => void;
    fallbackSrc?: string | StaticImageData;
    onClose:()=>void
  } & DialogProps
) => {
  const { availableAvatar, onAvatarSelected, fallbackSrc,onClose, ...rest } = props;

  const { memberFunction } = useGlobal();
  const { data } = useSession();

  const [selectedAvatar, setSelectedAvatar] = useState<UploadFile | null>(null);
  const {isDarkMode} =useDarkMode()
  useEffect(() => {
    if (!rest.open) {
      setSelectedAvatar(null);
    }
  }, [rest.open]);

  const defaultProfileImage = useMemo(() => {
    return (
      (!!data?.user?.isPaidMember
        ? memberFunction?.paidMemberCardDesign?.defaultProfileImage
        : memberFunction?.normalCardDesign?.defaultProfileImage) ?? null
    );
  }, [data, memberFunction]);

  return (
    <CustomDialog isOpen={rest.open} close={onClose}  >
      <div className={clsx("w-full p-8 flex flex-col gap-4    mx-auto  max-w-[85vw] md:max-w-lg lg:max-w-xl   xl:max-w-[40rem]  max-h-[85vh] overflow-auto ",
        isDarkMode?"bg-dark-base-200 text-dark-content-100 border-none":"bg-white"
      )}>
        <div>更改頭像</div>
        <div className=" w-full md:w-1/2  max-w-md mx-auto ">
          <div className="aspect-w-1 aspect-h-1 relative  ">
            <ImageFallback
              className="rounded-full"
              src={getStrapiMedia(selectedAvatar)}
              fallbackSrc={fallbackSrc}
              layout="fill"
            />
          </div>
        </div>

        <div className="flex flex-row flex-wrap gap-4 justify-center">
          {availableAvatar.map((avatar, index) => (
            <div
              key={index}
              className={clsx(
                avatar == selectedAvatar &&
                  "shadow-primary-500 shadow-md after:border-4 after:border-primary-500",
                "rounded-full w-12 h-12 relative hover:-translate-y-4  transition-all duration-500 cursor-pointer  after:w-full after:h-full after:absolute after:rounded-full after:transition-all after:left-0"
              )}
              onClick={() => setSelectedAvatar(avatar)}
              onKeyPress={(ev) =>
                ev.key === "Enter" ? setSelectedAvatar(avatar) : null
              }
              tabIndex={0}
            >
              <NextImage
                className={clsx("rounded-full")}
                src={getStrapiMedia(
                  getCorrespondingImage(avatar, 128, "medium")
                )}
                layout="fixed"
                width={48}
                height={48}
              />
            </div>
          ))}
          {/* reset icon */}
          {/* <div
            className={clsx(
              defaultProfileImage == selectedAvatar &&
                "shadow-primary-500 shadow-md after:border-4 after:border-primary-500",
              "rounded-full w-12 h-12 relative hover:-translate-y-4  transition-all duration-500 cursor-pointer  after:w-full after:h-full after:absolute after:rounded-full after:transition-all after:left-0"
            )}
            onClick={() => setSelectedAvatar(defaultProfileImage)}
            onKeyPress={(ev) =>
              ev.key === "Enter" ? setSelectedAvatar(defaultProfileImage) : null
            }
            tabIndex={0}
          >
            <NextImage
              className={clsx("rounded-full")}
              src={getStrapiMedia(
                getCorrespondingImage(defaultProfileImage, 128, "medium")
              )}
              layout="fixed"
              width="48px"
              height="48px"
            />
          </div> */}
        </div>
        <div className="flex flex-row-reverse">
          <button
            className="btnPrimary"
            disabled={!selectedAvatar}
            onClick={() => {
              selectedAvatar &&
                onAvatarSelected(
                  selectedAvatar == defaultProfileImage ? null : selectedAvatar
                );
            }}
          >
            確定
          </button>
        </div>
      </div>
    </CustomDialog>
  );
};
