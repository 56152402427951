import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  FC,
  RefObject,
} from "react";
import clsx from "clsx"
import { useDarkMode } from "@/lib/hooks/useDarkModeHook";
import { usePopper } from 'react-popper';

type CustomDropdownProps={
  isOpen:boolean
  close?:()=>void
  children:React.ReactNode
  isFullScreen?:boolean
}


export const CustomDropdown=(props:CustomDropdownProps)=>{
  const {isOpen,close,children ,isFullScreen=false}=props


  const {isDarkMode}=useDarkMode()
  const dropDownRef=useRef<HTMLDivElement>(null)


  const [positionY, setpositionY]=useState("bottom")
  const [positionX, setpositionX]=useState("right")

  // const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  // const { styles, attributes } = usePopper(referenceElement, dropDownRef.current);

  useEffect(()=>{
    isOpen&&dropDownRef&& handleResize()
  },[isOpen,dropDownRef])

  const handleResize=()=>{
    if(!dropDownRef.current)return
    const dropdownEl = dropDownRef.current;
    const dropdownRect = dropdownEl.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth ;

    const right = dropdownRect.right;
    const bottom = dropdownRect.bottom;

    if(right > windowWidth/2){
      //overflow
      setpositionX("right")
    }else{
      setpositionX("left")
    }
    if( bottom > windowHeight/2 ){
      //overflow
      setpositionY("bottom")
    }else {
      setpositionY("top")
    }

  }

  return (
    <div
      className={clsx(
        ` z-10    transition-[transform,opacity] ease-in-out duration-300
    opacity-0 scale-0
      w-auto    py-2 `,
        isOpen
          ? ` scale-100 opacity-100 overflow-auto  md:shadow-lg`
          : "scale-0 opacity-0 p-0",
        isDarkMode?"bg-dark-base-200 text-white":"bg-white  ",
        isFullScreen?"origin-top-right fixed lg:absolute lg:max-h-[85vh] top-0 bottom-0  right-0 left-0  lg:left-auto  lg:rounded-[10px]   lg:top-full lg:bottom-auto   ":
          "absolute max-h-[85vh] rounded-[10px]  shadow-lg",
       
        !isFullScreen&&positionX==="left"&&  "left-0 right-auto ",
        !isFullScreen&& positionX==="right"&&  "right-0 left-auto ",
        !isFullScreen&& positionY==="top"&&" top-full  bottom-auto",
        !isFullScreen&& positionY==="bottom"&&" bottom-full top-auto "
        ,
        !isFullScreen&& positionX==="left"&&positionY==="top"&&"origin-top-left",
        !isFullScreen&& positionX==="left"&&positionY==="bottom"&&"origin-bottom-left",
        !isFullScreen&&  positionX==="right"&&positionY==="top"&&"origin-top-right",
        !isFullScreen&& positionX==="right"&&positionY==="bottom"&&"origin-bottom-right",
      )}
      ref={dropDownRef}
    >
      {children}
    </div>
  )
}