import React, { useContext } from 'react'
import moment from "moment";

const UserContext = React.createContext({} as {
  updateCurrentTime?:number,
  updateCurrentTimeHandler?:()=>void
});
export const UserProvider = (props: {children?: React.ReactNode})=>{
  const [updateCurrentTime, setUpdateCurrentTime] = React.useState(moment().valueOf());

  const updateCurrentTimeHandler = () => {
    setUpdateCurrentTime(moment().valueOf());
  }
  return <UserContext.Provider value={{
    updateCurrentTime: updateCurrentTime,
    updateCurrentTimeHandler: updateCurrentTimeHandler
  }} >
    {props.children}
  </UserContext.Provider>
}

export const useUser = () => {
  const userG = useContext(UserContext)
  return userG 
}